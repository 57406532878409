var Aneox;
(function (Aneox) {
  (function (Library) {
    'use strict';

    var Router = (function () {
      
      var self;
      var basePath;
      
      var currentNode;
      var currentLocale;
      var appLocaleInit;
      var initApp;

      function Router() {
        self = this;
        initApp = 1;
      }
      
      function parseAnchors(locale) {
        var anchors;
        
        anchors = $('[router-href]');
        _.forEach(anchors, function (element) {
          var anchor = $(element);
          
          var path = self.getNodePath(anchor.attr('router-href'), locale);
          
          if (path === undefined) anchor.removeAttr('href');
          else anchor.attr('href', path);
        });
        
        anchors = $('[router-locale]');
        _.forEach(anchors, function (element) {
          var anchor = $(element);
          
          var path = self.getNodePath(currentNode.id, anchor.attr('router-locale'));
          
          if (path === undefined) anchor.removeAttr('href');
          else anchor.attr('href', path);
        });
      }
      
      function processPath(location, node, locale) {
        
        /*Change path if navigator language are different of the path language, priority to nav language at the loading*/
        if(appLocaleInit !== locale.locale && initApp === 1){
                      
            var nodePath = self.getNodePath(node.id, appLocaleInit);
            if(nodePath){
                self.setPath(nodePath);
            }else{
                self.setPath('/'+appLocaleInit);
            }
            initApp = 0;
            return;
        }
        
        currentNode = node;
        if (currentLocale !== locale) parseAnchors(locale.locale);
        currentLocale = locale;
        
        document.title = locale.title;
        document.querySelector('meta[name=description]').setAttribute('content', locale.description);
        document.querySelector('meta[name=keywords]').setAttribute('content', locale.keywords);
        document.querySelector('link[rel=canonical]').setAttribute('href', location.canonicalPath);

        _.forOwn(locale.opengraph, function (value, key) {
            document.querySelector('meta[property="og:' + key + '"]').setAttribute('content', value)
        });
        
        self.trigger('pageChange', location, node, locale);
      }


      Router.prototype.init = function(force, locale){
        
        if(locale) appLocaleInit = locale;

        if (!force && self.deferredRoutes) {
          return self.deferredRoutes(self.init.bind(this, true));
        }

        basePath = $('head base').attr('href');

        if (!_.isArray(Aneox.Data.Nodes.nodes)) Aneox.Data.Nodes.nodes = [Aneox.Data.Nodes.nodes];
        _.forEach(Aneox.Data.Nodes.nodes, function (node) {
          if (!_.isArray(node.locales)) node.locales = [node.locales];
        });
        
        var trimmedBasePath = (basePath.indexOf(location.origin) === 0) ? basePath.substring(location.origin.length) : basePath;
        
        if (history.emulate) {
          history.redirect('/', trimmedBasePath);
        }
        page.base(trimmedBasePath);

        _.forEach(Aneox.Data.Nodes.nodes, function (node) {
          _.forEach(node.locales, function (locale) {
            if (locale.locale === Aneox.Data.Config.rootLocale) {
              page(locale.path, function(location) { processPath(location, node, locale); });
              page(_.trim(locale.path, '/'), function(location) { processPath(location, node, locale); });
            }
            
            page('/' + locale.locale + locale.path, function(location) { processPath(location, node, locale); });
            page(_.trim('/' + locale.locale + locale.path, '/'), function(location) { processPath(location, node, locale); });
          });
        });

        page('*', function (route) {
          page(basePath, options);
        });

        var options = Aneox.Data.Config.routerMode === 'legacy' ? { click: false, popstate: false } : { hashbang: true };
        page(options);
        //page.bind(page, options);
        //setTimeout(page.bind(page, options));
      };
      
      Router.prototype.setPath = function (path) {
        if (Aneox.Data.Config.routerMode === 'legacy') window.location.pathname = basePath + path;
        else page(path);
      }
      
      Router.prototype.getNode = function (nodeId) {
        if (!nodeId) return currentNode;
        
        return _.find(Aneox.Data.Nodes.nodes, { id: nodeId });
      }
      
      Router.prototype.getNodePath = function (nodeId, locale) {
        if (!nodeId) return;

        var node = _.find(Aneox.Data.Nodes.nodes, { id: nodeId });
        if (!node) return;
        
        locale = locale || Aneox.Data.Config.rootLocale;
        var nodeLocale = _.find(node.locales, { locale: locale });
        
        if (locale === Aneox.Data.Config.rootLocale)
          return '#!' + nodeLocale.path;
          
        return '#!/' + locale + nodeLocale.path;
      }
      
      MicroEvent.mixin(Router)

      return Router;
    })();

    Library.Router = Router;
  })(Aneox.Library || (Aneox.Library = {}));
})(Aneox || (Aneox = {}));