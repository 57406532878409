var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Values = (function () {

      var self;

      var values;
      var valuesVisibility;

      var _bodyHeight;
      
      var hasPlayedThroughAnimation;

      var timeline;

      var videoLeftPosition;

      var heroVideoWrapper;
      var heroVideoPlaying = false;
      var heroVideoWidth = 1042;
      var heroVideoHeight = 400;
      
      var videoScrollActive = false;
      var oldScrollY;
      var newScrollY;
      var scrollpos;
      var targetscrollpos;
      var accel;
      var accelamount;
      var bounceamount;
      var videoLength = 56;
      var canvidControl;
      var videoSpriteLoaded;

      var diaporamaVideo;
      var terroirVideo;
      var cepageVideo;
      var climateVideo;
      var isVideoButtonClicked;
      var videoControls;
      var videoButton;
      var mousedownListener;
      var mouseupListener;
      var mouseupListenerBack;
      var videoSliderTimeline;

      function Values(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        values = $('#values');

        heroVideoWrapper = $('.values-hero');

        diaporamaVideo = document.getElementById('values-diaporama-video');
        terroirVideo = document.getElementById('values-video-terroir');
        cepageVideo = document.getElementById('values-video-cepage');
        climateVideo = document.getElementById('values-video-climate');

        // set up timeline
        timeline = new TimelineMax({ paused: true });
        timeline
          // mast enter animations
          .from('.values-title1', 1, { opacity: 0 })
          .from('.values-title1-rule', 1, { scaleX: 0 }, '-=.5')
          .from('.values-hero', 1, {opacity: 0}, '-=.5') /*Add for rempace scroll hero control*/
          .addPause()
          // diaporama enter animations
          .add('diaporama')
          .from('.values-keyword-passion', .5, { opacity: 0 })
          .to('.values-keyword-passion-underline-mask', .5,
            { scaleX: 0 }, '-=.5')
          .from('.values-keyword-and', .5, { opacity: 0 }, '-=.3')
          .from('.values-keyword-rigour span', .5, { opacity: 0 }, '-=.5')
          .from('.values-keyword-rigour-underline', .5,
            { scaleX: 0 }, '-=.3')
          .from('.values-diaporama-lead', .5, { opacity: 0 })
          .addPause()
          // diaporama text animation
          .add('diaporamaText')
          //.from('.values-text-1 p', 1, { opacity: 0 })
          .from('.values-text-1 hr', 1, { scaleX: 0 }, '-=.5')
          .addPause()
          // main enter animation
          .add('main')
          .from('.values-main-pre-title, .values-main-title', 1, { opacity: 0 })
          .from('.values-main-title-rule', 1, { scaleX: 0 })
          .from('.values-main-lead', 1, { opacity: 0 })
          .addPause()
          // end animation
          .add('end')
          .to('#values-end-background', 5, { transform: 'scale(1.15)' });

        // set up timeline for small videos with slider
        videoSliderTimeline = new TimelineMax({ paused: true })
          .call(function() {
            terroirVideo.play();
          }, null, null, 0.0001)
          .fromTo('.values-video-terroir-label', .5, { opacity: 0 }, { opacity: 1 }, 0)
          .call(function() {
            if (!terroirVideo.paused) terroirVideo.pause();
            cepageVideo.play();
          }, null, null, 3)
          .fromTo('.values-video-cepage-label', .5, { opacity: 0 }, { opacity: 1 }, 3)
          .call(function() {
            if (!cepageVideo.paused) cepageVideo.pause();
            climateVideo.play();
          }, null, null, 6)
          .fromTo('.values-video-climate-label', .5, { opacity: 0 }, { opacity: 1 }, 6)
          .call(function() {
            if (!climateVideo.paused) climateVideo.pause();
          }, null, null, 9);

        // set up video controls (small videos with slider)
        videoControls = $('.values-main-video-controls');
        videoButton = videoControls.children('button');
        videoLeftPosition = videoControls.position().left + 25.5;

        isVideoButtonClicked = false;

        mousedownListener = function(event) {
          event.preventDefault();
          isVideoButtonClicked = true;
        };

        mouseupListener = function(event) {
          if (!isVideoButtonClicked) return;

          isVideoButtonClicked = false;

          TweenMax.to(videoButton, .5, { left: 227 });
          TweenMax.to('#videoslider-arrow', .5, { opacity: 0, display: 'none', delay: .5 });
          TweenMax.to('#videoslider-circle', .5, { opacity: 1, display: 'block', delay: .5 });
          setTimeout(function() {
            $('.ab-circle').css('fill', '#aa7b40');
            $('.ab1').css('stroke', '#aa7b40');
          }, 500);

          videoSliderTimeline.play();
          $(this).off('mouseup touchend', mouseupListener);
          $(this).on('mouseup touchend', mouseupListenerBack);
        };

        mouseupListenerBack = function(event) {
          if (!isVideoButtonClicked) return;
          isVideoButtonClicked = false;

          TweenMax.to(videoButton, .5, { left: 0 });
          TweenMax.to('#videoslider-arrow', .5, { opacity: 1, display: 'block' });
          TweenMax.to('#videoslider-circle', .5, { opacity: 0, display: 'none' });

          $(this).off('mouseup touchend', mouseupListenerBack);

          resetVideoSlider();
        }

        videoButton.on('mousedown touchstart', mousedownListener);

        videoButton.on('mousemove touchmove', _.debounce(function(event) {
          if (!isVideoButtonClicked) return;

          var clientX = event.clientX || event.originalEvent.touches[0].clientX;
          var x = Math.max(0, Math.min(clientX - videoLeftPosition, 227));
          videoButton.css('left', x);
        }, 50));


        // set up scroll video        

        /*comment for rempace scroll hero control*/
        /* canvidControl = canvid({
          selector: heroVideoWrapper[0],
          videos: {
            video: {
              src: 'assets/valeurs/deux-mots-intro-sprite-90.jpg',
              cols: 6,
              frames: videoLength
            }
          },
          width: heroVideoWidth,
          height: heroVideoHeight,
          loaded: function() {
            $('#values-hero-poster').remove();
            videoSpriteLoaded = true;
          }
        }); */
        oldScrollY = heroVideoWrapper.offset().top;
        newScrollY;
        
        scrollpos = oldScrollY/200;
        targetscrollpos = scrollpos;
        accel = 0;

        accelamount = .1; //How fast the video will try to catch up with the target position. 1 = instantaneous, 0 = do nothing.
        bounceamount = 0.5; //value from 0 to 1 for how much backlash back and forth you want in the easing. 0 = no bounce whatsoever, 1 = lots and lots of bounce 

        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = values.css('display');
              var previousValuesVisibility = valuesVisibility;
              valuesVisibility = display !== 'none';
              
              if (!previousValuesVisibility && display !== 'none') {
                self.handleWindowResize(document.body.clientWidth, document.body.clientHeight);
              }
            }
          });
        });
        observer.observe(values[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Values.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        videoLeftPosition = $('.values-main-video-controls').position().left + 25.5;
        heroVideoWidth = Math.max(bodyWidth * .8, 1042);
        heroVideoHeight = 25/64 * heroVideoWidth;
        _bodyHeight = bodyHeight;
      };
      
      Values.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        timeline.progress(0);
      };
      
      Values.prototype.fastForwardAnimations = function () {
        timeline.pause().progress(1);
        TweenMax.set([
          '.values-title1',
          '.values-title1-rule',
          '.values-hero', /*Add for rempace scroll hero control*/
          '.values-keyword-and',
          '.values-keyword-passion',
          '.values-keyword-rigour > span',
          '.values-diaporama-lead',
          '.values-text-1 hr',
          '.values-main-pre-title',
          '.values-main-title',
          '.values-main-title-rule',
          '.values-main-lead'
        ], { willChange: 'auto' });
      };
      
      Values.prototype.mastEnterAnimation = function (force, timescale) {
        if (!videoScrollActive) {
          //self.bindVideoToScroll(); /*comment for rempace scroll hero control*/
        }
        var video = document.getElementById('history-hero');
        if (!video.paused) video.pause();
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        timeline.play();
        hasPlayedThroughAnimation.mast = true;
      };

      Values.prototype.bindVideoToScroll = function() {
        // control hero video with scroll

        var requestAnimationFrame = window.requestAnimationFrame || function(f) {
          setTimeout(f, 40);
        };

        requestAnimationFrame(animate);
      };

      Values.prototype.diaporamaEnterAnimation = function() {
        if (!videoScrollActive) {
          //self.bindVideoToScroll(); /*comment for rempace scroll hero control*/
        }

        if (hasPlayedThroughAnimation.diaporama) return;
        timeline.seek('diaporama').play();
        if (!this.animations.isTouchDevice) diaporamaVideo.play();
        hasPlayedThroughAnimation.diaporama = true;
      };

      Values.prototype.diaporamaTextAnimation = function() {
        if (hasPlayedThroughAnimation.diaporamaText) return;
        timeline.seek('diaporamaText').play();
        hasPlayedThroughAnimation.diaporamaText = true;
      };

      Values.prototype.mainEnterAnimation = function() {
        resetVideoSlider();
        var video = document.getElementById('wines-video-1');
        if (!video.paused) video.pause();
        if (hasPlayedThroughAnimation.main) return;
        timeline.seek('main').play();
        hasPlayedThroughAnimation.main = true;
      };

      Values.prototype.endAnimation = function() {
        if (hasPlayedThroughAnimation.end) return;
        timeline.seek('end').play();
        hasPlayedThroughAnimation.end = true;
        TweenMax.set([
          '.values-title1',
          '.values-title1-rule',
          '.values-hero', /*Add for rempace scroll hero control*/
          '.values-keyword-and',
          '.values-keyword-passion',
          '.values-keyword-rigour > span',
          '.values-diaporama-lead',
          '.values-text-1 hr',
          '.values-main-pre-title',
          '.values-main-title',
          '.values-main-title-rule',
          '.values-main-lead'
        ], { willChange: 'auto' });
      };

      function resetVideoSlider() {
        if (videoButton.css('left') === '227px') {
          videoButton.css('left', 0);
        }
        $('.ab-circle').css('fill', 'black');
        $('.ab1').css('stroke', 'black');
        $('.ab2').css({
          display: 'block',
          opacity: 1
        });
        $('.ab3').css({
          display: 'none',
          opacity: 0
        })
        videoSliderTimeline.progress(0);
        videoSliderTimeline.pause();
        [climateVideo, cepageVideo, terroirVideo].forEach(function(video) {
          if (!video.paused) video.pause();
          video.currentTime = 0;
        });
        $('body').off('mouseup touchend', mouseupListenerBack);
        $('body').on('mouseup touchend', mouseupListener);
      }

      function animate(){
        newScrollY = heroVideoWrapper.offset().top;

        if (newScrollY < -400 || newScrollY > _bodyHeight) {
          videoScrollActive = false;
          return;
        }
        videoScrollActive = true;
        
        targetscrollpos = videoLength * (newScrollY + 400) / (_bodyHeight + 400);

        //Accelerate towards the target:
        accel += (targetscrollpos - scrollpos)*accelamount;

        //clamp the acceleration so that it doesnt go too fast
        if (accel > 1) accel = 1;
        if (accel < -1) accel = -1;

        //move the video scroll position according to the acceleration and how much bouncing you selected:
        scrollpos = ((scrollpos + accel) * (bounceamount) + (targetscrollpos * (1-bounceamount)));

        //update video playback
        if (videoSpriteLoaded) {
          canvidControl.play('video');
          canvidControl.pause();
          var currentFrame = (videoLength - scrollpos).toFixed();
          canvidControl.setCurrentFrame(currentFrame);

        }

        requestAnimationFrame(animate);
      }

      return Values;
    })();

    Sequences.Values = Values;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));