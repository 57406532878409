var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Main = (function () {
      
      var initialized;

      function Main(animations) {
        this.animations = animations;
        
        this.timeline = new TimelineMax({ paused: true });
        this.scrollHeight = 0;
        this.duration = 0;
        this.keyframes = {};

        // side menu animation
        $('.side-menu').hover(function() {
          TweenMax.to('.side-menu-text', .5, { opacity: 1 });
        }, function() {
          TweenMax.to('.side-menu-text', .5, { opacity: 0 });
        });
      }
      
      Main.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        var previousProgress = this.timeline.progress();
        
        //TweenMax.killTweensOf(this.timeline);
        this.timeline.clear();
        
        var isTouchDevice = 'ontouchstart' in window;
        var isMac = navigator && navigator.userAgent && navigator.userAgent.indexOf('Mac') > -1;
        this.animations.scrollSpeed = isTouchDevice ? 1 : .6;

        var isSmallScreen = (bodyWidth <= 1024);
        
        // Precompute heights and timings
        var currentTime = 0;

        var ease = Linear.easeNone;
        var force3D = true;

        // HOME
        this.keyframes.home = currentTime;

        var homeIntroHeight = Math.min(368, bodyHeight - 350);
        var homeIntroTime = homeIntroHeight / bodyHeight;

        this.timeline
          .fromTo('.header-logo-wrapper', .1, { display: 'none' }, { display: 'flex' }, currentTime + .5)
          // mast enter animation
          .call(this.animations.sequences.home.mastEnterAnimation, null, this.animations.sequences.home, currentTime)
          // scroll content
          .fromTo('#home-mast', 1 - homeIntroTime, { y: 0 },
            { y: -bodyHeight + homeIntroHeight, ease: ease }, currentTime)
          .fromTo('#home-intro', 1 - homeIntroTime, { y: -2 },
            { y: -bodyHeight + homeIntroHeight - 2, ease: ease }, currentTime)
          .to('#home-mast', 1 - homeIntroTime, 
            { y: -bodyHeight + homeIntroHeight, ease: ease }, currentTime)
          // intro enter animation
          .call(this.animations.sequences.home.introEnterAnimation, null, this.animations.sequences.home, currentTime + .3);
        
        currentTime += 1 - homeIntroTime;

        // Jump to end of video / animation when out of viewport
        this.timeline.call(this.animations.sequences.home.fastForwardAnimations, null, this.animations.sequences.home, currentTime + .5);

        // HOME TO DOMAIN
        this.timeline
          // scroll content
          .fromTo('#domain', 1, { y: bodyHeight }, { y: 0, ease: ease }, currentTime)
          .to('#home', 1, { y: -bodyHeight, ease: ease }, currentTime)
          .fromTo('.domain-map-wrapper', 1, { y: 0 }, { y: bodyHeight, ease: ease }, currentTime)
          .fromTo('.domain-map-wrapper > div', 1, { y: 0 },
            { y: -bodyHeight, ease: Sine.easeOut }, currentTime + .5);

        currentTime++;
        this.keyframes.domain = currentTime;
        
        // DOMAIN
        // Mast enter animation
        this.timeline.call(this.animations.sequences.domain.mastEnterAnimation, 
          null, this.animations.sequences.domain, currentTime - .5);

        // Puligny map animation
        this.timeline.call(this.animations.sequences.domain.pulignyMapAnimation,
          null, this.animations.sequences.domain, currentTime + .5);

        // Leave title, enter Puligny
        this.timeline
          .fromTo('#domain-title-villages, .domain-title-villages-rule', 1,
            { y: 0 }, { y: -bodyHeight, ease: ease }, currentTime);
        
        currentTime += .75;
        
        this.timeline
          .fromTo('#domain-puligny', 1, { y: 0 }, { y: -bodyHeight, ease: ease }, currentTime)
          .fromTo('.domain-puligny-foreground', 1, { y: .25 * bodyHeight }, { y: 0, ease: ease }, currentTime)
          .fromTo('.domain-puligny-image', 2, { top: -30 }, { top: 150, ease: ease }, currentTime + 1);

        currentTime++;

        // Puligny enter animation
        this.timeline.call(this.animations.sequences.domain.pulignyEnterAnimation,
          null, this.animations.sequences.domain, currentTime - .5);

        // Leave Puligny
        this.timeline
          .to('#domain-puligny', 1, { y: -2 * bodyHeight, ease: ease }, currentTime)
          .to('.domain-puligny-foreground', 1, { y: -.25 * bodyHeight, ease: ease }, currentTime)
          .to('.domain-puligny-image', 1, { y: 0, ease: ease }, currentTime);

        // map animations go here
        this.timeline.call(this.animations.sequences.domain.volnayMapAnimation,
          null, this.animations.sequences.domain, currentTime + .5);
        currentTime++;

        // Enter Volnay
        this.timeline
          .fromTo('#domain-volnay', 1, { y: -bodyHeight }, { y: -2 * bodyHeight, ease: ease }, currentTime)
          .fromTo('.domain-volnay-foreground', 1, { y: .25 * bodyHeight }, { y: 0, ease: ease }, currentTime)
          .fromTo('.domain-volnay-image', 2, { top: -100 }, { top: 100, ease: ease }, currentTime - .1);

        currentTime++;

        // Volnay enter animation
        this.timeline.call(this.animations.sequences.domain.volnayEnterAnimation,
          null, this.animations.sequences.domain, currentTime - .5);

        // Leave Volnay
        this.timeline
          .to('#domain-volnay', 1, { y: -3 * bodyHeight, ease: ease }, currentTime)
          .to('.domain-volnay-foreground', 1, { y: -.25 * bodyHeight, ease: ease }, currentTime)
          .to('.domain-volnay-image', 1, { y: 0, ease: ease }, currentTime);

        // map animations
        this.timeline.call(this.animations.sequences.domain.mapAnimation,
          null, this.animations.sequences.domain, currentTime + .7);

        currentTime++;

        // Leave map, enter main
        this.timeline
          .fromTo('#domain-mast', 1, { y: 0 }, { y: -bodyHeight, ease: ease }, currentTime)
          .to('.domain-map-wrapper', 1, { y: 2 * bodyHeight, ease: ease }, currentTime)
          .to('.domain-map-wrapper > div', 1, { y: -2 * bodyHeight, ease: Sine.easeIn }, currentTime)
          .fromTo('#domain-main', 1, { y: -2 * bodyHeight }, { y: -3 * bodyHeight, ease: ease }, currentTime);

        // Main enter animation
        this.timeline.call(this.animations.sequences.domain.mainEnterAnimation, 
          null, this.animations.sequences.domain, currentTime + .5);

        currentTime++;

        var domainMainHeight = $('#domain-main').innerHeight();
        var domainMainTime = domainMainHeight / bodyHeight;
        var domainTotalHeight = 3 * bodyHeight + domainMainHeight;

        // Leave main, enter main part 2
        this.timeline
          .to('#domain-main', domainMainTime,
            { y: -domainTotalHeight, ease: ease }, currentTime)
          .fromTo('#domain-main2', 0, { y: 0 },
            { y: -3 * bodyHeight - 1 }, currentTime)
          .fromTo('#domain-main2', domainMainTime, { y: -3 * bodyHeight },
            { y: -domainTotalHeight - 1, ease: ease, immediateRender: false }, currentTime)
          .fromTo('.domain-v', 2, { y: bodyHeight },
            { y: -.2 * bodyHeight, ease: ease }, currentTime)
          .fromTo('.domain-v-image-wrapper, .domain-main2-description2', 2,
            { y: .5 * bodyHeight }, { y: -.25 * bodyHeight, ease: ease }, currentTime)
          .fromTo('.domain-p', 2, { y: .25 * bodyHeight },
            { y: -.125 * bodyHeight, ease: ease }, currentTime);

        currentTime += domainMainTime;

        this.timeline
          .fromTo('.domain-v-image-innerwrap', 1.5, { height: 347 },
            { height: 247 }, currentTime - .5)
          .fromTo('.domain-p-image', 1, { top: -35 }, { top: 35 }, currentTime - .5);

        // Main part 2 enter animation
        this.timeline.call(this.animations.sequences.domain.main2EnterAnimation, 
          null, this.animations.sequences.domain, currentTime - .5);

        var domainMain2Height = $('#domain-main2').innerHeight();
        var domainMain2Time = domainMain2Height / bodyHeight;
        domainTotalHeight += domainMain2Height;

        // Leave main part 2, enter end
        this.timeline
          .to('#domain-main2', domainMain2Time, { y: -domainTotalHeight, ease: ease }, currentTime)
          .set('#domain-end', { y: -domainTotalHeight }, currentTime)
          .fromTo('#domain-end-content', 1.2, { y: bodyHeight * 1.2 }, { y: 0, ease: ease }, currentTime)
          .call(this.animations.sequences.domain.endAnimation, null,
            this.animations.sequences.domain, currentTime + .5);

        currentTime += 1.2;

        // DOMAIN TO PEOPLE
        this.timeline
          // scroll content
          //.fromTo('#domain', 1.2, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime)
          // .set('#people', { clearProps: 'display', immediateRender: true }, currentTime)
          //.to('#people', 1.2, { display: 'block', ease: ease, force3D: force3D }, currentTime)
          .fromTo('#people', 1.2, { y: bodyHeight * 1.2 }, { y: 0, ease: ease }, currentTime)
          .to('#domain-end-content', 1.2, { y: -bodyHeight * 1.2, ease: ease }, currentTime);

        var peopleTitleHeight = 270;
        var peopleTitleTime = peopleTitleHeight / bodyHeight;

        this.timeline
          .fromTo('.people-g', .25, { opacity: 0 }, { opacity: 1 },
            currentTime + peopleTitleTime + 1)
          .fromTo('.people-g-image-wrapper', 2, { y: .25 * bodyHeight },
            { y: -.25 * bodyHeight, ease: ease }, currentTime + peopleTitleTime)
          .fromTo('.people-h-image', 2.2, { y: -150 }, { y: 150, ease: ease }, currentTime)
          .fromTo('.people-g-image-innerwrap, .people-name-guillaume', 1.5,
            { y: 100 }, { y: -100, ease: ease }, currentTime + 1)
          .fromTo('.people-g-image', 1.5, { y: -100 },
            { y: 100, ease: ease }, currentTime + 1);

        currentTime += 1.2;
        this.keyframes.people = currentTime;

        // PEOPLE
        // Mast enter animation
        this.timeline.call(this.animations.sequences.people.mastEnterAnimation,
          null, this.animations.sequences.people, currentTime - 1);

        var peopleMastHeight = $('#people-mast').innerHeight();
        var peopleMastTime = peopleMastHeight / bodyHeight;

        // Leave mast, enter vocation
        this.timeline
          .to('#people-mast', peopleMastTime, { y: -peopleMastHeight, ease: ease }, currentTime)
          .fromTo('#people-vocation', peopleMastTime, { y: 0 }, { y: -peopleMastHeight, ease: ease }, currentTime);

        currentTime += peopleMastTime;

        this.timeline.fromTo('.people-vocation-bg', 3, { y: -200 },
          { y: 400, ease: ease }, currentTime - 1);

        // Vocation enter animation
        this.timeline.call(this.animations.sequences.people.vocationEnterAnimation,
          null, this.animations.sequences.people, currentTime - 1);

        var peopleVocationHeight = $('#people-vocation').innerHeight();
        var peopleVocationTime = peopleVocationHeight / bodyHeight;

        // Leave vocation, enter diaporama
        this.timeline
          .to('#people-vocation', peopleVocationTime,
            { y: -peopleMastHeight - peopleVocationHeight, ease: ease }, currentTime)
          .fromTo('#people-diaporama', peopleVocationTime, 
            { y: -peopleMastHeight, ease: ease },
            { y: -peopleMastHeight - peopleVocationHeight, ease: ease }, currentTime)
          // image scroll animations
          .fromTo('.people-visual-3', peopleVocationTime + 1,
            { y: -100 }, { y: 50, ease: ease }, currentTime)
          .fromTo('.people-visual-4-innerwrap', 1.5,
            { y: 0 }, { y: -100, ease: ease }, currentTime + 2)
          .fromTo('.people-visual-4', 1.5, { y: 0 }, { y: 100, ease: ease},
            currentTime + 2);

        currentTime += peopleVocationTime;

        // image scroll animations
        this.timeline
          .fromTo('.people-visual-5', peopleVocationTime + .5,
            { y: -100 }, { y: 50, ease: ease }, currentTime - 1)
          .fromTo('.people-visual-5-wrapper', peopleVocationTime + .5,
            { y: 100 }, { y: -50, ease: ease }, currentTime - 1)
          .fromTo('.people-text-2', peopleVocationTime + .5,
            { y: 100 }, { y: -50, ease: ease}, currentTime - 1)
          .fromTo('.people-visual-7-innerwrap', peopleVocationTime + .5,
            { y: 75 }, { y: -30, ease: ease }, currentTime)
          .fromTo('.people-visual-7', peopleVocationTime + .5,
            { y: -75 }, { y: 30, ease: ease }, currentTime)
          .fromTo('.people-visual-6-innerwrap', peopleVocationTime + .2,
            { y: 0 }, { y: -150, ease: ease }, currentTime)
          .fromTo('.people-visual-6', peopleVocationTime + .2,
            { y: 0 }, { y: 150, ease: ease }, currentTime);

        var peopleDiaporamaHeight = $('#people-diaporama').innerHeight();
        var peopleDiaporamaTime = peopleDiaporamaHeight / bodyHeight;

        // Leave diaporama, enter end
        this.timeline
          .to('#people-diaporama', peopleDiaporamaTime,
            { y: -peopleMastHeight - peopleVocationHeight - peopleDiaporamaHeight - 50, ease: ease },
            currentTime)
          .set('#people-end',
            { y: -peopleMastHeight - peopleVocationHeight - peopleDiaporamaHeight, ease: ease },
            currentTime)
          .fromTo('#people-end-content', peopleDiaporamaTime + .2,
            { y: peopleVocationHeight + (.2 * bodyHeight) }, { y: 0, ease: ease }, currentTime)
          // end animation
          .call(this.animations.sequences.people.endAnimation, null,
            this.animations.sequences.people, currentTime + .5);

        currentTime += peopleDiaporamaTime + .2;
        // end animation goes here

        // PEOPLE TO HISTORY
        this.timeline
          // scroll content
          //.fromTo('#people', 1.2, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime)
          //.set('#history', { clearProps: 'display', immediateRender: true }, currentTime)
          //.to('#history', 1.2, { display: 'block', ease: ease, force3D: force3D }, currentTime)
          .fromTo('#history', 1.2, { y: bodyHeight * 1.2 }, { y: 0, ease: ease }, currentTime)
          .to('#people-end-content', 1.2, { y: -bodyHeight * 1.2, ease: ease }, currentTime)
          // background text scrolls slower
          .fromTo('.history-background-1', 1, { y: -.5 * bodyHeight, ease: ease }, { y: 0 }, currentTime);

        currentTime += 1.2;
        this.keyframes.history = currentTime;

        // HISTORY
        // mast enter animation
        this.timeline.call(this.animations.sequences.history.mastEnterAnimation,
          null, this.animations.sequences.history, currentTime - .5);

        // 2017 enter animation
        this.timeline.call(this.animations.sequences.history.timeline2017EnterAnimation,
          null, this.animations.sequences.history,
          currentTime + $('.timeline-1885').innerHeight() / bodyHeight - 1);

        var historyMastHeight = $('#history-mast').innerHeight();
        var historyMastTime = historyMastHeight / bodyHeight;

        // Leave mast, enter main
        this.timeline
          .fromTo('#history-mast', historyMastTime, { y: 0 }, { y: -historyMastHeight, ease: ease }, currentTime)
          .fromTo('#history-main', historyMastTime, { y: 0 }, { y: -historyMastHeight - 1, ease: ease }, currentTime)
          // image scroll animations
          .fromTo('.history-visual-1', 1.5, { y: -100 }, { y: 50, ease: ease }, currentTime)
          .fromTo('.history-visual-2-innerwrap', 1.5,
            { y: 40 }, { y: -70, ease: ease }, currentTime + .1)
          .fromTo('.history-visual-2', 1.5, { y: -40 },
            { y: 70, ease: ease }, currentTime + .1);
        
        // background text scrolls slower
        this.timeline.to('.history-background-1', 1, { y: .5 * bodyHeight, ease: ease }, currentTime);

        // foreground image scrolls faster
        var historyForegroundStartTime = currentTime + 
          $('.history-visual-4').position().top / bodyHeight;
        this.timeline
          .fromTo('.history-visual-4-wrapper', 1, { y: 0 }, { y: -.05 * bodyHeight, ease: ease }, historyForegroundStartTime)
          .to('.history-visual-4-wrapper', 1, { y: -.2 * bodyHeight, ease: ease }, historyForegroundStartTime + 1)
          // image scroll animations
          .fromTo('.history-visual-4', 1, { y: -100 }, { y: 0, ease: ease }, historyForegroundStartTime)
          .fromTo('.history-visual-3, .history-visual-3-overflow', 2,
            { y: -200 }, { y: 100, ease: ease }, historyForegroundStartTime)
          .fromTo('.history-visual-5-innerwrap', 1.5,
            { y: 25 }, { y: -25, ease: ease }, historyForegroundStartTime + 1.5)
          .fromTo('.history-visual-5', 1.5, { y: -25 },
            { y: 25, ease: ease }, historyForegroundStartTime + 1.5);

        // second background image scrolls slower
        var historyBackgroundStartTime = currentTime + 
          ($('.timeline-1885').innerHeight() + $('.history-background-2').position().top) / bodyHeight;
        this.timeline
          .fromTo('.history-background-2', 2, { y: 0 }, { y: .5 * bodyHeight, ease: ease }, historyBackgroundStartTime);

        // image scroll animation
        var startTime2017 = currentTime + $('.timeline-1885').innerHeight() / bodyHeight - 1.3;

        this.timeline
          .fromTo('.history-visual-6-innerwrap', 1.5, { y: 50 },
            { y: -50, ease: ease }, startTime2017 + 1)
          .fromTo('.history-visual-6', 1.5, { y: -50 },
            { y: 50, ease: ease }, startTime2017 + 1)
          .fromTo('.history-2017-1', 1.5, { y: 0 }, { y: -50, ease: ease },
            startTime2017 + 1);

        currentTime += historyMastTime;

        this.timeline.call(this.animations.sequences.history.mainEnterAnimation,
          null, this.animations.sequences.history, currentTime - 1);

        var historyMainHeight = $('#history-main').innerHeight();
        var historyMainTime = historyMainHeight / bodyHeight;

        // Leave main, enter end
        this.timeline
          .to('#history-main', historyMainTime,
            { y: -historyMastHeight - historyMainHeight, ease: ease }, currentTime)
          .set('#history-end',
            { y: -historyMastHeight - historyMainHeight }, currentTime)
          .fromTo('#history-end-content', historyMainTime + .2,
            { y: historyMastHeight - historyMainHeight + (bodyHeight * .2) },
            { y: 0, ease: ease }, currentTime)
          .call(this.animations.sequences.history.endAnimation, null,
            this.animations.sequences.history, currentTime + .5);

        currentTime += historyMainTime + .2;

        // HISTORY TO VALUES
        this.timeline
          // scroll content
          //.fromTo('#history', 1.2, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime)
          //.set('#values', { clearProps: 'display', immediateRender: true }, currentTime)
          //.to('#values', 1.2, { display: 'block', ease: ease, force3D: force3D }, currentTime)
          .fromTo('#values', 1.2, { y: bodyHeight * 1.2 }, { y: 0, ease: ease }, currentTime)
          .to('#history-end-content', 1.2, { y: -bodyHeight * 1.2, ease: ease }, currentTime);

        currentTime += 1.2;
        this.keyframes.values = currentTime;

        // VALUES
        var valuesTotalHeight = 0;

        this.timeline
          // mast enter animation
          .call(this.animations.sequences.values.mastEnterAnimation, 
            null, this.animations.sequences.values, currentTime - .5)
          // hero video playback controlled with scroll
          .to('#values-hero', 1.5, { currentTime: 1.5 }, currentTime - .5);

        var valuesMastHeight = $('#values-mast').innerHeight();
        var valuesMastTime = valuesMastHeight / bodyHeight;

        // leave mast, enter diaporama
        valuesTotalHeight += valuesMastHeight;
        this.timeline
          .fromTo('#values-mast', 1, { y: 0 }, { y: -valuesTotalHeight, ease: ease }, currentTime)
          .fromTo('#values-diaporama', 0, { y: 0 }, { y: -1, ease: ease }, currentTime)
          .to('#values-diaporama', 1, { y: -valuesTotalHeight - 1, ease: ease }, currentTime)

        currentTime++;

        // avoid content showing through gaps
        this.timeline.set('#domain, #people, #history', { display: 'none' }, currentTime);

        // diaporama enter animation
        this.timeline.call(this.animations.sequences.values.diaporamaEnterAnimation, 
          null, this.animations.sequences.values, currentTime - .5);

        // image scroll animations
        this.timeline
          .fromTo('.values-visual-1', 1.5, { y: 0 },
            { y: -75, ease: ease }, currentTime - .5)
          .fromTo('.values-visual-1-wrapper', 1.5, { y: 0 },
            { y: 75, ease: ease }, currentTime - .5)
          .fromTo('.values-visual-2-innerwrap', 1.5, { y: 50 },
            { y: -50 , ease: ease }, currentTime - .5)
          .fromTo('.values-visual-2', 1.5, { y: -50 },
            { y: 50, ease: ease }, currentTime - .5)
          .fromTo('.values-diaporama-button', 1.5, { y: 50 },
            { y: -50, ease: ease }, currentTime - .5)
          .fromTo('.values-visual-3', 1.5, { y: -50 },
            { y: 50, ease: ease }, currentTime + .5)
          .fromTo('.values-visual-3-wrapper', 1.5, { y: 50 },
            { y: -50, ease: ease }, currentTime + .5)
          .fromTo('.values-text-2', 1.5, { y: 50 },
            { y: -50, ease: ease }, currentTime + .5)
          .fromTo('.values-visual-5-innerwrap', 1.5, { y: 0 },
            { y: -70, ease: ease }, currentTime + .5)
          .fromTo('.values-visual-5', 1.5, { y: 0 },
            { y: 70, ease: ease }, currentTime + .5)
          .fromTo('.values-visual-4-innerwrap', 1.5,
            { y: 35 }, { y: -35, ease: ease }, currentTime + 1)
          .fromTo('.values-visual-4', 1.5, { y: -35 },
            { y: 35, ease: ease }, currentTime + 1);

        var valuesDiaporamaHeight = $('#values-diaporama').innerHeight();
        var valuesDiaporamaTime = valuesDiaporamaHeight / bodyHeight;

        // diaporama text animation
        this.timeline.call(this.animations.sequences.values.diaporamaTextAnimation,
          null, this.animations.sequences.values, currentTime);

        // leave diaporama, enter main
        valuesTotalHeight += valuesDiaporamaHeight;
        this.timeline
          .to('#values-diaporama', valuesDiaporamaTime, { y: -valuesTotalHeight, ease: ease }, currentTime)
          .fromTo('#values-main', valuesDiaporamaTime, { y: -bodyHeight - 1.1 },
            { y: -valuesTotalHeight - 1, ease: ease }, currentTime)
          // letters scroll faster than videos
          .fromTo('.values-letter', 1.5, { y: .5 * bodyHeight }, 
            { y: -.5 * bodyHeight, ease: ease }, currentTime + valuesDiaporamaTime - .2);

        currentTime += valuesDiaporamaTime;

        // main enter animation
        this.timeline.call(this.animations.sequences.values.mainEnterAnimation, 
          null, this.animations.sequences.values, currentTime - .5);

        var valuesMainHeight = $('#values-main').innerHeight();
        var valuesMainTime = valuesMainHeight / bodyHeight;

        // leave main, enter end
        valuesTotalHeight += valuesMainHeight;
        this.timeline
          .to('#values-main', valuesMainTime, { y: -valuesTotalHeight, ease: ease }, currentTime)
          .set('#values-end', { y: -valuesTotalHeight }, currentTime)
          .fromTo('#values-end-content', valuesMainTime + .2,
            { y: valuesTotalHeight + (bodyHeight * .2) },
            { y: 0, ease: ease }, currentTime)
          .call(this.animations.sequences.values.endAnimation, null,
            this.animations.sequences.values, currentTime + .7);

        currentTime += valuesMainTime + .2;

        // VALUES TO WINES
        this.timeline
          // scroll content
          // .fromTo('#values', 1.2, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime)
          // .set('#wines', { clearProps: 'display', immediateRender: true }, currentTime)
          // .to('#wines', 1.2, { display: 'block', ease: ease, force3D: force3D }, currentTime)
          .fromTo('#wines', 1.2, { y: bodyHeight * 1.2 }, { y: 0, ease: ease }, currentTime)
          .to('#values-end-content', 1.2, { y: -bodyHeight * 1.2, ease: ease }, currentTime);
        
        currentTime += 1.2;
        this.keyframes.wines = currentTime;

        // mast enter animation
        this.timeline.call(this.animations.sequences.wines.mastEnterAnimation, 
          null, this.animations.sequences.wines, currentTime - .5);

        // foreground scrolls faster than background
        this.timeline
          .fromTo('.wines-keywords', 2,
            { y: .5 * bodyHeight }, { y: -.5 * bodyHeight, ease: ease }, currentTime + .2);

        var winesMastPhotoBlockTime = $('.wines-photo-block-1').innerHeight() / bodyHeight;
        this.timeline
          .fromTo('#wines-video-1, .button-play-wines-1, .wines-visual-4-wrapper', 2,
            { y: .5 * bodyHeight }, { y: -.5 * bodyHeight, ease: ease },
            currentTime + winesMastPhotoBlockTime);

        var winesMastHeight = $('#wines-mast').innerHeight();
        var winesMastTime = winesMastHeight / bodyHeight;

        // leave mast, enter main
        this.timeline
          .to('#wines-mast', winesMastTime, { y: -winesMastHeight, ease: ease }, currentTime)
          .fromTo('#wines-main', winesMastTime, { y: 0 },
            { y: -winesMastHeight, ease: ease }, currentTime)
          .fromTo('.wines-mast-bg', 1.5, { y: -100 }, { y: 50, ease: ease }, currentTime)
          .fromTo('.wines-mast-hero-bg', 1.2,
            { y: -157 }, { y: 0, ease: ease }, currentTime - .2)
          .fromTo('.wines-mast-hero-fg1, .wines-mast-hero-fg2', 1.2, { y: -78 },
            { y: 79, ease: ease}, currentTime - .2)

        currentTime += winesMastTime;

        // main enter animation
        this.timeline.call(this.animations.sequences.wines.mainEnterAnimation, 
          null, this.animations.sequences.wines, currentTime - .5);

        // main scroll animation
        this.timeline.call(this.animations.sequences.wines.mainScrollAnimation,
          null, this.animations.sequences.wines,
          currentTime + ($('#wines-main-1').innerHeight() / bodyHeight) - 1);

        var winesMainHeight = $('#wines-main').innerHeight();
        var winesMainTime = winesMainHeight / bodyHeight;

        // leave main, enter list
        this.timeline
          .to('#wines-main', winesMainTime,
            { y: -winesMastHeight - winesMainHeight, ease: ease }, currentTime)
          .fromTo('#wines-list', winesMainTime,
            { y: -winesMastHeight },
            { y: -winesMastHeight - winesMainHeight, ease: ease, immediateRender: false },
            currentTime);

        currentTime += winesMainTime;
        this.keyframes.winesList = currentTime;

        var winesListHeight = $('#wines-list').innerHeight();
        var winesListTime = winesListHeight / bodyHeight;

        // leave list, enter map
        this.timeline
          .to('#wines-list', winesListTime,
            { y: '-=' + winesListHeight, ease: ease }, currentTime)
          .fromTo('#wines-map', winesListTime, { y: -winesMastHeight - winesMainHeight },
            { y: -winesMastHeight - winesMainHeight - winesListHeight,
              ease: ease, immediateRender: false },
            currentTime);

        var winesMapHeight = $('#wines-map').innerHeight();
        var winesMapTime = winesMapHeight / bodyHeight;

        // map enter animation
        this.timeline.call(this.animations.sequences.wines.mapEnterAnimation,
          null, this.animations.sequences.wines, currentTime + .6);

        currentTime += winesListTime;

        var winesTotalHeight = winesMastHeight + winesMainHeight + winesListHeight + winesMapHeight;

        // Leave map, enter end
        this.timeline
          .to('#wines-map', winesMapTime,
            { y: -winesTotalHeight, ease: ease }, currentTime)
          .set('#wines-end', { y: -winesTotalHeight }, currentTime)
          .call(this.animations.sequences.wines.endAnimation, null,
            this.animations.sequences.wines, currentTime + .5);

        currentTime += winesMapTime;

        this.keyframes.winesVintages = currentTime - (500 / bodyHeight);

        // WINES TO CONTACT
        this.timeline
          // scroll content
          // .fromTo('#wines', 1, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime)
          // .set('#contact', { clearProps: 'display', immediateRender: true }, currentTime)
          // .to('#contact', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime)
          .fromTo('#contact', 1, { y: bodyHeight }, { y: 0, ease: ease }, currentTime)

        currentTime += 1.2;
        this.keyframes.contact = currentTime;

        this.timeline
          // mast enter animation
          .call(this.animations.sequences.contact.mastEnterAnimation, null,
            this.animations.sequences.contact, currentTime - 1);

        var contactHeight = 876;
        var contactTime = contactHeight / bodyHeight;

        this.timeline.fromTo('#contact-mast', contactTime - 1, { y: 0 },
            { y: bodyHeight - contactHeight }, currentTime);

        currentTime += contactTime - .9;

        this.timeline.addPause(currentTime);
                
        this.duration = this.timeline.totalDuration();
        this.scrollHeight = currentTime * bodyHeight;
        
        if (!initialized) {
          this.timeline.progress(previousProgress);
          this.timeline.invalidate();
          initialized = true;
        } else {
          this.timeline.invalidate();
          this.timeline.progress(previousProgress);
        }
      };

      Main.prototype.setTime = function (time, duration, ease) {
        var safeTime = Math.min(Math.max(time, 0), this.duration);

        //TweenMax.killTweensOf(this.timeline);
        if (duration === undefined || duration === null || duration === 0) {
          this.timeline.time(safeTime);

          var activeTweens = TweenMax.getTweensOf(this.timeline);
          if (activeTweens.length) {
            var self = this;
            activeTweens[0].eventCallback('onComplete', function() {
              self.timeline.time(safeTime);
            });
          }

        } else {
          TweenMax.to(this.timeline, duration, { time: safeTime, ease: ease });
        }
      }

      Main.prototype.getTime = function() {
        return this.timeline.time();
      }

      return Main;
    })();

    Sequences.Main = Main;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));