var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Domain = (function () {

      var self;

      var domain;
      var domainVisibility;
      var domainMap;
      
      var renderer;
      var stage;
      var map;
      var dissolveFilter;
      var dissolveFilter2;

      var pulignyVideo;
      var volnayVideo;
      var heroVideo;

      var timeline;

      var hasPlayedThroughAnimation;

      function Domain(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        domain = $('#domain');
        domainMap = $('#domain-map');

        // set up map animations
        renderer = new PIXI.autoDetectRenderer(domainMap.width(), domainMap.height(), {
          clearBeforeRender: false,
          transparent: true
        });
        renderer.plugins.interaction.destroy();

        domainMap.prepend(renderer.view);

        stage = new PIXI.Container();
        
        map = new PIXI.Sprite(resources['domain-map'].texture);
  
        stage.addChild(map);
  
        dissolveFilter = new DissolveFilter(resources['home-heightmap'].texture);
        dissolveFilter2 = new DissolveFilter(resources['volnay-heightmap'].texture);
  
        map.filters = [dissolveFilter];
  
        stage.addChild(map);
      
        dissolveFilter.clampValues = [1.0,0,0];
        dissolveFilter.startTimes = [0,0,0];
        dissolveFilter.coverColor = [1,1,1];
        dissolveFilter2.clampValues = [1.0,0,0];
        dissolveFilter2.startTimes = [0,0,0];
        dissolveFilter2.coverColor = [1,1,1];

        // set up map content
        (function() {
          var snap = Snap('#domain-map-puligny');
          Snap.load('assets/domaine/puligny.svg', function(svg) {
            snap.append(svg);
            var snap2 = Snap('#domain-map-volnay');
            Snap.load('assets/domaine/volnay.svg', function(svg) {
              snap2.append(svg);
              var snap3 = Snap('#domain-map-domain');
              Snap.load('assets/domaine/domaine.svg', function(svg) {
                snap3.append(svg);
                setupTimeline();
              });
            });
          });
        }) ();

        if (resources['domain-puligny-video']) {
          pulignyVideo = resources['domain-puligny-video'].data;
          pulignyVideo.id = 'domain-puligny-video';
          pulignyVideo.className = 'domain-puligny-video domain-puligny-foreground';
          pulignyVideo.poster = 'assets/domaine/puligny-90.jpg';
          $('.domain-puligny-sublabel').after(pulignyVideo);
        } else {

          /* var pulignyPoster = resources['domain-puligny-poster'].data;
          pulignyPoster.className = 'domain-puligny-video domain-puligny-foreground';
          $('.domain-puligny-sublabel').after(pulignyPoster); */
        }

        if (resources['domain-volnay-video']) {
          volnayVideo = resources['domain-volnay-video'].data;
          volnayVideo.id = 'domain-volnay-video';
          volnayVideo.className = 'domain-volnay-video domain-volnay-foreground';
          volnayVideo.poster = 'assets/domaine/volnay-90.jpg';
          $('.domain-volnay-sublabel').after(volnayVideo);
        } else {
          /* var volnayPoster = resources['domain-volnay-poster'].data;
          volnayPoster.className = 'domain-volnay-video domain-volnay-foreground';
          $('.domain-volnay-sublabel').after(volnayPoster); */
        }

        // heroVideo = resources['domain-hero'].data;
        // heroVideo.id = 'domain-hero';
        // heroVideo.className = 'hero-video overflow-center';
        // $('#domain-hero-wrapper').append(heroVideo);
        heroVideo = document.getElementById('domain-hero');

        /*[pulignyVideo, volnayVideo].forEach(function(video) {
          if (video) {
            video.muted = true;
            video.loop = true;
          }
        });  */


        pulignyVideo = document.getElementById('domain-puligny-video');
        volnayVideo = document.getElementById('domain-volnay-video');
        
        if(!self.animations.isTouchDevice){
          pulignyVideo = document.getElementById('domain-puligny-video');
          volnayVideo = document.getElementById('domain-volnay-video');

          [pulignyVideo, volnayVideo].forEach(function(video){
            if (video) {
              video.muted = true;
              video.loop = true;
            }
          });
        }

        // Set up timeline
        timeline = new TimelineMax({ paused: true });
        function setupTimeline() {
          function animateRing(selector) {
            var ease = Sine.easeInOut;
            var ringTimeline = new TimelineMax();

            ringTimeline.to(selector, 1, { scale: 1.3, transformOrigin: 'center', ease: ease });
            
            // if (selector === '#puligny-cercle') {
            //   ringTimeline.to('#domain-map-arrow', 1, { opacity: 1, ease: ease }, '-=1');
            // }

            ringTimeline.to(selector, 1, { scale: 1, ease: ease });
            
            // if (selector === '#puligny-cercle') {
            //   ringTimeline.to('#domain-map-arrow', 1, { opacity: .2, ease: ease }, '-=1');
            // }

            ringTimeline.call(animateRing, [selector]);
          }
          timeline
            // mast enter animations
            .from('.domain-title-villages', .5, { opacity: 0, immediateRender: true })
            .from('.domain-title-villages-rule', .5, { scaleX: 0 })
            .to(dissolveFilter, 1, 
              { time: 1, onUpdate: function () { renderer.render(stage); } }, '-=1')
            .addPause()
            // Puligny map animations
            .add('pulignyMap')
            .from('#puligny-cercle', 1, { opacity: 0, scale: 1.5, transformOrigin: 'center' })
            .from('#puligny-point', 1, { scale: 0, transformOrigin: 'center' }, '-=1')
            .from('#puligny-nom', 1, { opacity: 0 }, '-=.5')
            // .from('#domain-map-arrow', 1, { display: 'none', opacity: 0 }, '-=1')
            .call(animateRing, ['#puligny-cercle'], null, '+=.5')
            .addPause()
            // Puligny enter animations
            .add('puligny')
            .to('#domain-map-arrow', 1, { opacity: 0 })
            .from('.domain-puligny-label, .domain-puligny-sublabel', 1, { opacity: 0 })
            .from('.domain-puligny-description hr', 1, { scale: 0, transformOrigin: 'center' }, '-=.5')
            .from('.domain-puligny-description p', 1, { opacity: 0 }, '-=.7')
            .addPause()
            // Volnay map animations
            .add('volnayMap')
            .fromTo('.domain-map-wrapper', 1, { x: 166 }, { x: 0 })
            .call(function() {
              map = new PIXI.Sprite(resources['domain-map-2'].texture);
              map.filters = [dissolveFilter2];
              stage.addChild(map);
            }, null, null, '-=1')
            .fromTo(dissolveFilter2, 1, { time: 0 }, { time: 1, onUpdate: function() {
              renderer.render(stage);
            } }, '-=1')
            .to('#puligny-cercle', 1, { opacity: 0 })
            .to('#puligny-point', 1, { fill: '#b5a39d' }, '-=1')
            .to('#puligny-nom', 1, { fill: '#b5a39d', scale: .8, transformOrigin: 'center' }, '-=1')
            .call(function() {
              $('#puligny-cercle').remove();
            })
            .from('#volnay-cercle', 1, { opacity: 0, scale: 1.5, transformOrigin: 'center' }, '-=1')
            .from('#volnay-point', 1, { scale: 0, transformOrigin: 'center' }, '-=1')
            .from('#volnay-nom', 1, { opacity: 0 }, '-=.5')
            .call(animateRing, ['#volnay-cercle'], null, '+=.5')
            .addPause()
            // Volnay enter animations
            .add('volnay')
            .from('.domain-volnay-label, .domain-volnay-sublabel', 1, { opacity: 0 })
            .from('.domain-volnay-description hr', 1, { transform: 'scaleX(0)' }, '-=.5')
            .from('.domain-volnay-description p', 1, { opacity: 0 }, '-=.7')
            .addPause()
            // final map animations
            .add('map')
            .to('#volnay-cercle', 1, { opacity: 0 })
            .to('#volnay-point', 1, { fill: '#b5a39d' }, '-=1')
            .to('#volnay-nom', 1, { fill: '#b5a39d', scale: .8, transformOrigin: 'center' }, '-=1')
            .call(function() {
              $('#volnay-cercle').remove();
            })
            .from('#domaine-cercle', 1, { opacity: 0, scale: 1.5, transformOrigin: 'center' }, '-=1')
            .from('#domaine-point', 1, { scale: 0, transformOrigin: 'center' }, '-=1')
            .from('#domaine-nom', 1, { opacity: 0 }, '-=.5')
            .call(animateRing, ['#domaine-cercle'], null, '+=.5')
            .addPause()
            // main enter animations
            .add('main')
            .staggerFrom('.domain-pre-title-domain, .domain-title-domain',
              1, { opacity: 0 }, .5)
            .from('.domain-title-domain-rule', 1, { scaleX: 0 }, '-=.5')
            .from('.domain-main-lead', 1, { opacity: 0 }, '-=.5')
            .addPause()
            // main2 enter animations
            .add('main2')
            .from('.domain-main2-description1 hr', 1, { scaleX: 0 })
            .from('.domain-main2-description1 p', 1, { opacity: 0 }, '-=.7')
            .from('.domain-main2-description2 hr', 1, { scaleX: 0 }, '-=.5')
            .from('.domain-main2-description2 p', 1, { opacity: 0 }, '-=1.2')
            .addPause()
            // end animation
            .add('end')
            .to('#domain-end-background', 5, { transform: 'scale(1.15)' });

            self.timeline = timeline;
        }
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = domain.css('display');
              var previousDomainVisibility = domainVisibility;
              domainVisibility = display !== 'none';
              
              if (!previousDomainVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(domain[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Domain.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        if (!domainVisibility) return;

        var width = domainMap.width();
        var height = width * 495 / 1145;
        
        renderer.resize(width, height);
        
        var mapScale = width / 1145;
  
        map.scale.x = mapScale;
        map.scale.y = mapScale;
        
        renderer.render(stage);
      };
      
      Domain.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        timeline.progress(0);
        dissolveFilter.time = 0;
        renderer.render(stage);
      };
      
      Domain.prototype.fastForwardAnimations = function () {
        timeline.pause().progress(1);

        $('#domain-map').append(self.resources['domain-map-2'].data);

        TweenMax.set([
          '.domain-title-villages',
          '.domain-title-villages-rule',
          '.domain-pre-title-domain',
          '.domain-title-domain',
          '.domain-title-domain-rule',
          '.domain-main-lead',
          '.domain-main2-description1 hr',
          '.domain-main2-description1 p',
          '.domain-main2-description2 hr',
          '.domain-main2-description2 p'
        ], { willChange: 'auto' });
      };
      
      Domain.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        // if (timescale === undefined) timescale = 1;
        
        timeline.play();
        
        hasPlayedThroughAnimation.mast = true;
      };

      Domain.prototype.pulignyMapAnimation = function() {
        if (pulignyVideo && !pulignyVideo.paused) pulignyVideo.pause();
        if (hasPlayedThroughAnimation.pulignyMap) return;
        timeline.seek('pulignyMap').play();
        hasPlayedThroughAnimation.pulignyMap = true;
      };

      Domain.prototype.pulignyEnterAnimation = function() {
        if (pulignyVideo && pulignyVideo.paused && !this.animations.isTouchDevice) {
          pulignyVideo.play();
        }
        if (hasPlayedThroughAnimation.puligny) return;
        timeline.seek('puligny').play();
        hasPlayedThroughAnimation.puligny = true;
      };

      Domain.prototype.volnayMapAnimation = function() {
        if (pulignyVideo && pulignyVideo.paused && !this.animations.isTouchDevice) {
          pulignyVideo.play();
        }
        if (volnayVideo && !volnayVideo.paused) volnayVideo.pause();
        if (hasPlayedThroughAnimation.volnayMap) return;
        timeline.seek('volnayMap').play();
        hasPlayedThroughAnimation.volnayMap = true;
      };

      Domain.prototype.volnayEnterAnimation = function() {
        if (pulignyVideo && !pulignyVideo.paused) pulignyVideo.pause();
        if (volnayVideo && volnayVideo.paused && !this.animations.isTouchDevice) {
          volnayVideo.play();
        }
        if (hasPlayedThroughAnimation.volnay) return;
        timeline.seek('volnay').play();
        hasPlayedThroughAnimation.volnay = true;
      };

      Domain.prototype.mapAnimation = function() {
        if (volnayVideo && volnayVideo.paused && !this.animations.isTouchDevice) {
          volnayVideo.play();
        }
        if (!heroVideo.paused) heroVideo.pause();
        if (hasPlayedThroughAnimation.map) return;
        timeline.seek('map').play();
        hasPlayedThroughAnimation.map = true;
      };

      Domain.prototype.mainEnterAnimation = function() {
        if (volnayVideo && !volnayVideo.paused) volnayVideo.pause();
        if (heroVideo.paused && !this.animations.isTouchDevice) {
          heroVideo.play();
        }
        if (hasPlayedThroughAnimation.main) return;
        timeline.seek('main').play();
        hasPlayedThroughAnimation.main = true;
      };

      Domain.prototype.main2EnterAnimation = function() {
        if (heroVideo.paused && !this.animations.isTouchDevice) {
          heroVideo.play();
        }
        if (hasPlayedThroughAnimation.main2) return;
        timeline.seek('main2').play();
        hasPlayedThroughAnimation.main2 = true;
      };

      Domain.prototype.endAnimation = function() {
        if (!heroVideo.paused) heroVideo.pause();
        if (hasPlayedThroughAnimation.end) return;
        timeline.seek('end').play();
        hasPlayedThroughAnimation.end = true;
        TweenMax.set([
          '.domain-title-villages',
          '.domain-title-villages-rule',
          '.domain-pre-title-domain',
          '.domain-title-domain',
          '.domain-title-domain-rule',
          '.domain-main-lead',
          '.domain-main2-description1 hr',
          '.domain-main2-description1 p',
          '.domain-main2-description2 hr',
          '.domain-main2-description2 p'
        ], { willChange: 'auto' });
      };

      return Domain;
    })();

    Sequences.Domain = Domain;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));