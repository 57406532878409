var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Home = (function () {

      var self;

      var home;
      var homeVisibility;
      var homeMast;
      
      var hasPlayedThroughAnimation;

      var timeline;
      var introTimeline;

      function Home(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        timeline = new TimelineMax({ paused: true });
        introTimeline = new TimelineMax({ paused: true });

        var homeVideo;


        if(!animations.isTouchDevice){
          homeVideo = document.getElementById('home-video');
          homeVideo.muted = true;
          homeVideo.loop = true;
        }else{
          TweenMax.set('#home-video-image-wrapper', { display: 'block' });
        }

        

        if (!resources['home-video']) {
          //TweenMax.set('#home-video-image-wrapper', { display: 'block' });
        } else {
          /* homeVideo = resources['home-video'].data;
          homeVideo.id = 'home-video';
          homeVideo.className = 'home-video overflow-center';
          homeVideo.poster = 'assets/home/accueil-90.jpg';
          homeVideo.muted = true;
          homeVideo.loop = true;
          $('.home-video-mask').after(homeVideo); */
        }
        
        hasPlayedThroughAnimation = {};
        
        home = $('#home');
        homeMast = $('#home-mast');

        var homeImage = resources['home-image'].data;
        homeImage.id = 'home-video-image';
        homeImage.className = 'home-video-image overflow-center';
        $('#home-video-image-wrapper').append(homeImage);

        // Set up timelines
        (function() {
          var marginX = $(window).width() / 2 - $('.home-logo-bg').outerWidth()/2;
          var marginY = $(window).height() / 2 - $('.home-logo-bg').outerHeight()/2;

          /* if (!resources['home-video']) {
            timeline.to('#home-video-image-wrapper', 7, { scale: 1.3 });
          } else {
            timeline
            .call(function() {
              console.log('play');
              homeVideo.play();
            })
            .from(homeVideo, .5, { opacity: 0 });
          } */

          if(!animations.isTouchDevice){
            timeline
            .call(function() {
              homeVideo.play();
            })
            .from(homeVideo, .5, { opacity: 0 });
          }else{
            timeline.to('#home-video-image-wrapper', 7, { scale: 1.3 });
          }

          timeline
          .set('.home-logo-bg', { display: 'flex' }, 0)
          .fromTo('#home-video-mask', 5, { borderWidth: '15px' }, 
              { borderWidth: marginY + 'px ' + marginX + 'px' }, 0)
          .fromTo('#home-logo', 1.5, { opacity: 0 }, { opacity: 1 }, 2)
          .from('#menu-button', .5, { opacity: 0 }, 1)
          .from('.menu-line:eq(0)', .5, { scaleX: 0, transformOrigin: 'left' }, 1.5)
          .from('.menu-line:eq(1)', .5, { scaleX: 0, transformOrigin: 'right' }, 1.7)
          .from('.menu-line:eq(2)', .5, { scaleX: 0, transformOrigin: 'left' }, 1.9)
          .from('.home-title-rule', .5, { transform: 'scaleX(0)' }, 1)
          .from('.home-title', .5, { opacity: 0 }, 1.5);
          /* if (resources['home-video']) {
            timeline
            .call(function() {
              if (!homeVideo.paused) homeVideo.pause();
            }, null, null, 7.56)
            .set('#home-video-image-wrapper', { display: 'block' })
            .to(homeVideo, 1, { opacity: 0, display: 'none' });
          } */

          if(!animations.isTouchDevice){
            timeline
            .call(function() {
              if (!homeVideo.paused) homeVideo.pause();
            }, null, null, 7.56)
            .set('#home-video-image-wrapper', { display: 'block' })
            .to(homeVideo, 1, { opacity: 0, display: 'none' });
          }


          timeline.call(function() {
            hasPlayedThroughAnimation.mast = true;
          });

          introTimeline
            .to('.home-title-block', .25, { opacity: 0 })
            .from('.header-logo', .25, { y: 47.5 })
            .staggerFrom('.home-intro-text-line', .25, { y: 53 }, .1)
            .from('.home-scroll-arrow svg', .25, { y: -60 })
            .call(function() {
              $('.home-scroll-arrow').addClass('home-scroll-arrow-animate');
            });
        }) ();

        // set up menu button hover behaviour
        $('#menu-button').hover(function() {
          TweenMax.fromTo('.menu-line-thick:eq(0)', .5,
            { opacity: 1, scaleX: 0, transformOrigin: 'left' },
            { scaleX: 1, immediateRender: false });
          TweenMax.fromTo('.menu-line-thick:eq(1)', .5,
            { opacity: 1, scaleX: 0, transformOrigin: 'right' },
            { scaleX: 1, immediateRender: false, delay: .2 });
          TweenMax.fromTo('.menu-line-thick:eq(2)', .5,
            { opacity: 1, scaleX: 0, transformOrigin: 'left' },
            { scaleX: 1, immediateRender: false, delay: .4 });
        }, function() {
          TweenMax.to('.menu-line-thick:eq(0)', .5,
            { scaleX: 0, transformOrigin: 'left' });
          TweenMax.to('.menu-line-thick:eq(1)', .5,
            { scaleX: 0, transformOrigin: 'right', delay: .2 });
          TweenMax.to('.menu-line-thick:eq(2)', .5,
            { scaleX: 0, transformOrigin: 'left', delay: .4 });
        });

        $('.menu-close').hover(function() {
          var lines = $(this).find('.close-line-thick').get();
          TweenMax.fromTo(lines[0], .5,
            { opacity: 1, scale: 0, transformOrigin: 'top left' },
            { scale: 1, immediateRender: false });
          TweenMax.fromTo(lines[1], .5,
            { opacity: 1, scale: 0, transformOrigin: 'bottom left' },
            { scale: 1, immediateRender: false, delay: .2 });
        }, function() {
          var lines = $(this).find('.close-line-thick').get();
          TweenMax.to(lines[0], .5, { scale: 0, transformOrigin: 'top left' });
          TweenMax.to(lines[1], .5, { scale: 0, transformOrigin: 'bottom left', delay: .2 });
        });
        
        this.resetAnimations();
      }
      
      Home.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
      };
      
      Home.prototype.resetAnimations = function () {
        introTimeline.progress(0);
      };
      
      Home.prototype.fastForwardAnimations = function () {
        timeline.pause().progress(1);
        introTimeline.pause().progress(1);
        hasPlayedThroughAnimation = { mast: true, intro: true };
      };
      
      Home.prototype.mastEnterAnimation = function (force, timescale) {
        if (hasPlayedThroughAnimation.mast || !self.animations.enabled) {
          return;
        }
        timeline.play();
      };

      Home.prototype.introEnterAnimation = function() {
        if (hasPlayedThroughAnimation.intro || !self.animations.enabled) {
          return;
        }
        introTimeline.play();

        hasPlayedThroughAnimation.intro = true;
      };

      return Home;
    })();

    Sequences.Home = Home;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));