var Aneox;
(function (Aneox) {
  'use strict';

  var Animations = (function () {

    function Animations(locale) {
      var self = this;

      var sequences;
      var orderedSequences;

      var isSiteEntered = false;
      
      Object.defineProperties(this, {
        sequences: {
          get: function () {
            return sequences;
          }
        },
        orderedSequences: {
          get: function () {
            return orderedSequences;
          }
        }
      });
      
      this.enabled = false;
      this.scrollSpeed = 1;

      var isTouchDevice = 'ontouchstart' in window;
      this.isTouchDevice = isTouchDevice;
      
      PIXI.loaders.Resource.setExtensionLoadType('mp4', PIXI.loaders.Resource.LOAD_TYPE.VIDEO);
      PIXI.loaders.Resource.setExtensionLoadType('webm', PIXI.loaders.Resource.LOAD_TYPE.VIDEO);
      PIXI.loaders.Resource.setExtensionLoadType('jpg', PIXI.loaders.Resource.LOAD_TYPE.IMAGE);
      PIXI.loaders.Resource.setExtensionLoadType('png', PIXI.loaders.Resource.LOAD_TYPE.IMAGE);
      var loader = new PIXI.loaders.Loader();
      var numberLoaded = 0;
      var totalNumber = isTouchDevice ? 4 : 5;

      var videoTest = document.createElement('video');
      var ie = window.navigator.userAgent.indexOf('MSIE') > -1 ||
               window.navigator.userAgent.indexOf('Trident/') > -1 ||
               window.navigator.userAgent.indexOf('Edge/') > -1;

      if (!ie && videoTest.canPlayType('video/mp4')) {
        if (!isTouchDevice) {
          //loader.add('home-video', 'assets/home/accueil.mp4');
        }
        /* loader
        .add('domain-puligny-video', 'assets/domaine/puligny-short.mp4')
        .add('domain-volnay-video', 'assets/domaine/volnay-short.mp4'); */
      }
      else if (!ie && videoTest.canPlayType('video/webm')) {
        if (!isTouchDevice) {
          //loader.add('home-video', 'assets/home/accueil.webm');
        }
        /* loader
        .add('domain-puligny-video', 'assets/domaine/puligny-short.webm')
        .add('domain-volnay-video', 'assets/domaine/volnay-short.webm'); */
      } else {
        /* loader
        .add('domain-puligny-poster', 'assets/domaine/puligny-90.jpg')
        .add('domain-volnay-poster', 'assets/domaine/volnay-90.jpg'); */
      }

      loader
      .add('home-image', 'assets/home/tablette-90.jpg')
      .add('domain-map', 'assets/domaine/fond_carte_01-90.jpg')
      .add('domain-map-2', 'assets/domaine/fond_carte_02-90.jpg')
      .add('volnay-heightmap', 'heightmaps/volnay.png')
      .add('home-heightmap', 'heightmaps/home.png')
      //.add('wines-mast-hero-fg1', 'assets/vins/raisins_rouge.png')
      //.add('wines-mast-hero-fg2', 'assets/vins/raisins_blanc.png');

      loader
      .once('complete', enterSite)
      .on('load', function(loader, resource) {
        //console.log(resource);
        numberLoaded++;
        var proportion = numberLoaded / totalNumber;
        var percentage = (100 * proportion).toFixed(0);
        $('#preloader-value').text(percentage);
        TweenMax.to('#preloader-progress', 1, { scaleX: proportion });
      })
      .on('error', function(error, loader, resource) {
        console.log('error loading file', resource.name, error.message);
      })
      .load();    
        
      TweenMax.to('.preloader-content', 1, { opacity: 1 });

      function enterSite(loader, resources) {
        if (isSiteEntered) return;
        isSiteEntered = true;
          
        sequences = {
          'main': new Aneox.Sequences.Main(self, resources, locale),
          'home': new Aneox.Sequences.Home(self, resources, locale),
          'domain': new Aneox.Sequences.Domain(self, resources, locale),
          'people': new Aneox.Sequences.People(self, resources, locale),
          'history': new Aneox.Sequences.History(self, resources, locale),
          'values': new Aneox.Sequences.Values(self, resources, locale),
          'wines': new Aneox.Sequences.Wines(self, resources, locale),
          'contact': new Aneox.Sequences.Contact(self, resources, locale)
        };
        
        orderedSequences = [
          sequences['home'],
          sequences['domain'],
          sequences['people'],
          sequences['history'],
          sequences['values'],
          sequences['wines'],
          sequences['contact']
        ];

        self.trigger('complete');

      }
    }
    
    MicroEvent.mixin(Animations);

    return Animations;
  })();

  Aneox.Animations = Animations;
})(Aneox || (Aneox = {}));