var Aneox;
(function (Aneox) {
  'use strict';

  var App = (function () {
    
    var self;

    var isTouchDevice;

    var scrollRetainCount;
    var lastScrollAction;
    var manualScroll = false;
    
    var currentNodeId;
    var currentKeyframeId;
    var currentNodeType;

    var firstLoad = true;

    var activeVideo;

    var scrollPosition;

    function App(fonts, locale, router, analytics, animations, wines) {
      this.fonts = fonts;
      this.locale = locale;
      this.router = router;
      this.analytics = analytics;
      this.animations = animations;
      this.wines = wines;

      self = this;
      
      var disableBodyScroll = bodyScrollLock.disableBodyScroll;

      disableBodyScroll(document.querySelector('body'));


      // Quick and dirty desktop/mobile check
      if (screen && screen.availWidth < 1024) window.location.href = 'http://mobile.henri-boillot.com';
      
      isTouchDevice = 'ontouchstart' in window;
                  
      if ($.cookie('agecheck') === 'true') {
        $('#age-check').remove();
        $('#overlay-light').hide();
        self.analytics.startAnalytics();
        scrollRetainCount = 0;
      } else {
        $('#age-check').show();
        (function hideOverlay() {
          if ($('#age-check').hasClass('lazyloaded')) {
            TweenMax.set('#overlay-light', { opacity: 0, display: 'none' });
          } else {
            window.requestAnimationFrame(hideOverlay);
          }
        }) ();
        scrollRetainCount = 1;
      }
      
      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      var nowText = now.toISOString().substring(0, 10).split('-');
      $('#age-check-date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);

      this.router.bind('pageChange', function (path, node, nodeLocale) {
        if (node.type === 'legal') {
          self.showLegal();
          if (!currentNodeId) {
            currentNodeId = node.id;
            currentNodeType = node.type;
            
            self.locale.setLocale(nodeLocale.locale);
          }
          return;
        }

        currentNodeId = node.id;
        currentKeyframeId = node.id;
        currentNodeType = node.type;

        if (currentNodeType === 'wine-sheet') {
          currentKeyframeId = 'wines';
          self.wines.openPage(currentNodeId);
          if (lastScrollAction !== undefined) return;
        }
        
        if (currentNodeType === 'vintage-sheet') {
          currentKeyframeId = 'wines';
          self.wines.openVintagePage(currentNodeId);
          if (lastScrollAction !== undefined) return;
        }

        self.locale.setLocale(nodeLocale.locale);

        updateMenu(currentNodeId);

        if (manualScroll) {
          manualScroll = false;
          return;
        }

        if (self.animations.sequences) {
          var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
          
          incrementScrollRetainCount();
        
          self.animations.enabled = false;
          resetAnimations();

          self.animations.sequences.main.setTime(time);
          lastScrollAction = 'pageChange';

          self.animations.enabled = true;
          self.animations.sequences[currentKeyframeId].mastEnterAnimation();

          decrementScrollRetainCount();
        }
      });

      this.locale.bind('localeChange', function (locale) {
        $('[router-locale]').removeClass('active')
            .filter('[router-locale=' + locale + ']').addClass('active');

        self.wines.setupList('domaine', 'white', locale);
        self.wines.setupList('domaine', 'red', locale);
        self.wines.setupList('maison', 'white', locale);
        self.wines.setupList('maison', 'red', locale);

      });
      
      this.animations.bind('complete', function (locale) {
        self.router.init(true, self.locale.getLocale());

        var node = self.router.getNode();

        if (isTouchDevice) {
          $('video').each(function() {
            var video = this;
            if ($(video).hasClass('values-main-video')) return;
            if ($(video).hasClass('video-overlay-video')) return;
            if (video.id === 'values-hero') return;
            var specificClass;
            switch (video.id) {
              case 'domain-puligny-video':
                specificClass = 'domain-puligny-foreground button-play-puligny';
                break;
              case 'domain-volnay-video':
                specificClass = 'domain-volnay-foreground button-play-volnay';
                break;
              case 'history-2017-video':
                specificClass = 'button-play-history-2017';
                break;
              case 'values-diaporama-video':
                specificClass = 'button-play-values-diaporama';
                break;
              case 'wines-video-1':
                specificClass = 'button-play-wines-1';
            }
            var playButton =
              $('<button class="button-play button-unstyled ' +
                specificClass + '">' +
              '<img src="assets/interface/play_video.svg" width="58" height="59.3">' +
            '</button>');
            video.loop = false;
            playButton.click(function() {
              video.play();
              TweenMax.to(playButton, .5, { opacity: 0, display: 'none' });
            });
            $(video).on('ended', function() {
              TweenMax.to(playButton, .5, { opacity: 1, display: 'block' });
            })
            $(video).after(playButton);
          });
        }
        TweenMax.set('section', {'display': 'block'});
        TweenMax.to('#preloader', 0.5, { opacity: 0, display: 'none', delay: 1.5, onComplete: function () {
          $('#preloader').remove();
          if ($('#age-check').length === 0) {
            resetAnimations();
            self.animations.enabled = true;
            if ([
              'wine-sheet',
              'vintage-sheet',
              'legal'
            ].indexOf(currentNodeType) === -1) {
              setTimeout(function() {
                self.animations.sequences[currentKeyframeId].mastEnterAnimation();
              }, 1000);
            }
          }
          }
        });
        handleWindowResize();
        
        // lazySizesConfig.loadMode = 3;
        // lazySizesConfig.preloadAfterLoad = true;
        
        
        if (node.id !== 'legal') {
          var currentKeyframeId = node.id;
          if (node.type === 'wine-sheet') currentKeyframeId = 'wines';
          
          var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
          
          self.animations.sequences.main.setTime(time);
            
          lastScrollAction = 'pageChange';
        } else {
          self.animations.sequences.main.setTime(0);
          self.animations.sequences.home.fastForwardAnimations();
        }
      });
      
      // Window resize handling
      var handleWindowResize = function () {
        var bodyWidth = document.body.clientWidth;
        //var bodyHeight = document.body.clientHeight;
        var bodyHeight = $('#domain-puligny').height(); // avoid vh bug on iOS Safari
        
        _.forEach(self.animations.sequences, function (sequence) { sequence.handleWindowResize(bodyWidth, bodyHeight); });
        
        lastScrollAction = 'windowResize';
      };
      var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, {leading: false, trailing: true});
      $(window).resize(throttledWindowResizeHandler);
      
      // Wheel handling
      var position = 0;
      var scrollEndTimeout;
      
      $(window).keydown(function (e){        
        switch(e.keyCode){
            case 38:

                $(window).trigger({
                  type: "mousewheel",
                  wheelDelta: 1,
                  deltaY: 5,
                  deltaFactor: 4
                });
                break;
            case 40:
                $(window).trigger({
                  type: "mousewheel",
                  wheelDelta: -1,
                  deltaY: -5,
                  deltaFactor: 4
                });

                break;
        }
      });

      $(window).on('mousewheel', function (event) {
        clearTimeout(scrollEndTimeout);

        if (scrollRetainCount !== 0) return;

        firstLoad = false;
        
        if (_.includes(['pageChange','windowResize','scrollToKeyframe'], lastScrollAction))
          position = self.animations.sequences.main.timeline.time() / self.animations.sequences.main.duration * self.animations.sequences.main.scrollHeight;
          
        position -= event.deltaY * event.deltaFactor * 1.5 * self.animations.scrollSpeed;
        position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));

        var scrollPercent = position / self.animations.sequences.main.scrollHeight;
        var time = scrollPercent * self.animations.sequences.main.duration;
                
        self.animations.sequences.main.setTime(time, 0.25, Power0.easeNone);
        checkKeyframes.call(self, time);
        
        lastScrollAction = 'mouseWheel';

        scrollEndTimeout = setTimeout(function() {
          position -= event.deltaY * event.deltaFactor * 1.5 * self.animations.scrollSpeed;
          position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));

          scrollPercent = position / self.animations.sequences.main.scrollHeight;
          time = scrollPercent * self.animations.sequences.main.duration;

          self.animations.sequences.main.setTime(time, 1, Power2.easeOut);
          checkKeyframes.call(self, time);
        }, 100);
      });
      
      // Touch handling
      var lastPanDirectionY;
      var lastPanDeltaY;
      var lastPanVelocityY;
      var targetPanId;
      
      if (isTouchDevice) {
        var mc = new Hammer.Manager(document.body);
        mc.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL }));
        mc.on('panstart panmove panend', function (event) {
          if (scrollRetainCount !== 0) return;
          
          if (event.type === 'panstart') targetPanId = event.srcEvent.target.getAttribute('id');
          if (targetPanId === 'scrollbar' || targetPanId === 'scroller') return;
          
          if (event.type === 'panstart') lastPanDeltaY = 0;
          
          if (_.includes(['pageChange','windowResize','scrollToKeyframe'], lastScrollAction))
            position = self.animations.sequences.main.timeline.time() / self.animations.sequences.main.duration * self.animations.sequences.main.scrollHeight;
          
          var absoluteVelocityY = Math.abs(lastPanVelocityY);
          var scrollDuration;
          
          if (event.type === 'panend' && absoluteVelocityY > 0.5) {
            position -= absoluteVelocityY * lastPanDirectionY * 200;
            scrollDuration = absoluteVelocityY / 3;
          } else {
            position -= event.deltaY - lastPanDeltaY;
          }
          
          position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));
  
          lastPanDirectionY = (event.deltaY - lastPanDeltaY) >= 0 ? 1 : -1;
          lastPanDeltaY = event.deltaY;
          lastPanVelocityY = event.velocityY;
  
          var scrollPercent = position / self.animations.sequences.main.scrollHeight;
          var time =  scrollPercent * self.animations.sequences.main.duration;
        
          self.animations.sequences.main.setTime(time, scrollDuration);
          checkKeyframes.call(self, time);
          
          lastScrollAction = 'pan';
        });
      }

      // side menu links: fade to blank overlay before redirect
      $('.side-menu-link, .header-logo-wrapper').click(function(event) {
        event.preventDefault();
        var nodeId = $(this).attr('href');
        var overlay;
        if (nodeId === 'people' || nodeId === 'wines') {
          overlay = '#overlay-dark';
        } else {
          overlay = '#overlay-light';
        }
        incrementScrollRetainCount();
        lastScrollAction = 'pageChange';
        new TimelineLite().fromTo(overlay, .5,
          { display: 'none', opacity: 0 },
          { display: 'block', opacity: 1 }
        )
        .call(setPage, [nodeId], self)
        .call(self.animations.sequences.main.setTime,
              [self.animations.sequences.main.keyframes[nodeId]],
              self.animations.sequences.main)
        .call(decrementScrollRetainCount)
        .to(overlay, .5, { display: 'none', opacity: 0 }, '+=1');
      });
    }
    
    var incrementScrollRetainCount = function () {
      scrollRetainCount++;
    };
    
    var decrementScrollRetainCount = function () {
      scrollRetainCount = Math.max(scrollRetainCount - 1, 0);
    };
    
    var setPage = function (nodeId, isNotKeyframe) {
      if (nodeId === this.router.getNode().id) return;
      
      var path = this.router.getNodePath(nodeId, this.locale.getLocale());
      if (path === undefined) return;
      
      this.router.setPath(path);
    }
    
    var checkKeyframes = _.debounce(function (time) {
      var keyframe;
      var keyframeTime = 0;
      
      _.forOwn(this.animations.sequences.main.keyframes, function (value, key) {
        if (time >= value && value >= keyframeTime) {
          keyframe = key;
          keyframeTime = value;
        }
      });
      
      if (!keyframe) return;

      manualScroll = true;
      setPage.call(this, keyframe);
    }, 250);

    var updateMenu = function(nodeId) {
      $('.menu-link').removeClass('active');
      $('.menu-link[router-href="' + nodeId + '"]').addClass('active');

      if (nodeId === 'home') {
        TweenMax.staggerTo('.side-menu-item', .5, { transform: 'scaleX(0)' }, .2);
      } else {
        TweenMax.staggerTo('.side-menu-item', .5, { transform: 'none' }, .2);
      }
      $('.side-menu-item').removeClass('active');
      $('#side-menu-' + nodeId).addClass('active');

      if ($('.menu-button-text:visible').length) {
        TweenMax.to('.menu-button-text:not(#menu-button-' + nodeId + ')',
                    .5, { display: 'none', opacity: 0 });
        TweenMax.to('#menu-button-' + nodeId,
                    .5, { display: 'block', opacity: 1 });
      } else {
        TweenMax.set('.menu-button-text:not(#menu-button-' + nodeId + ')',
                     { display: 'none', opacity: 0 });
        TweenMax.set('#menu-button-' + nodeId,
                     { display: 'block', opacity: 1});
      }

      if (nodeId === 'people' || nodeId === 'wines') {
        TweenMax.to('#menu-button-icon .st0', .5, { stroke: '#595a5b' });
        $('.side-menu-item').addClass('dark');
        if (nodeId === 'people') $('.header .menu-button').addClass('dark');
        else $('.header .menu-button').addClass('black');
      } else {
        TweenMax.to('#menu-button-icon .st0', .5, { stroke: '#000000' });
        $('.side-menu-item, .header .menu-button').removeClass('dark black');
      }
    }
    
    App.prototype.ageCheckResult = function (result) {
      if (!result) return;
      
      $.cookie('agecheck', 'true');
      TweenMax.to('#age-check', 0.5, { opacity: 0, onComplete: function () {
        self.analytics.startAnalytics();
        $('#age-check').remove();
          resetAnimations();
          if (self.animations) {
            self.animations.enabled = true;
           
            if (self.animations.sequences && self.animations.sequences[currentKeyframeId]) {
              self.animations.sequences[currentKeyframeId].mastEnterAnimation();
            }
          }
          decrementScrollRetainCount();
       } 
      });
    };

    App.prototype.scrollTo = function(scrollTime) {
      firstLoad = false;
      self.animations.sequences.main.setTime(scrollTime, .5);
    };

    App.prototype.openMenu = function() {
      firstLoad = false;
      scrollPosition = self.animations.sequences.main.getTime();
      incrementScrollRetainCount();
      TweenMax.fromTo('#menu-wrapper', 1, { height: 0 }, { height: '100%' });
    };

    App.prototype.closeMenu = function() {
      decrementScrollRetainCount();
      if (scrollPosition) {
        self.animations.sequences.main.setTime(scrollPosition);
      }
      manualScroll = false;
      TweenMax.to('#menu-wrapper', 1, { height: 0 });
    };

    var legalText = $('#legal-text');
    var legalTextInner = $('#legal-text-inner');
    var legalTextHeight = legalText.innerHeight();
    var legalTextInnerHeight = legalTextInner.innerHeight();

    App.prototype.showLegal = function() {
      incrementScrollRetainCount();
      TweenMax.fromTo('#legal-wrapper', 1, { height: 0 }, { height: '100%' });

      var y = 0;
      legalText.on('mousewheel', function(event) {
        y = Math.max(Math.min(0, y + event.deltaY), legalTextHeight - legalTextInnerHeight);
        TweenMax.to(legalTextInner, .5, { y: y });
      });

      if (isTouchDevice) {
        var mc = new Hammer.Manager(document.body);
        mc.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL }));
        mc.on('panmove', function (event) {          
          y = Math.max(Math.min(0, y + event.deltaY), legalTextHeight - legalTextInnerHeight);
          TweenMax.to(legalTextInner, .5, { y: y });
        });
      }
    };

    App.prototype.hideLegal = function() {
      if (currentNodeId === 'legal') {
        currentNodeId = 'home';
        currentNodeType = 'basic-page';
        currentKeyframeId = 'home';
        self.router.setPath('/');
      } else {
        var path = this.router.getNodePath(currentNodeId, this.locale.getLocale());
        self.router.setPath(path);
      }

      legalText.off('mousewheel panmove');
      TweenMax.set(legalTextInner, { y: 0 });

      decrementScrollRetainCount();
      manualScroll = false;
      TweenMax.to('#legal-wrapper', 1, { height: 0 });
    };

    App.prototype.openVideo = function(video) {
      incrementScrollRetainCount();
      TweenMax.fromTo('#video-overlay-wrapper', 1, { height: 0 }, { height: '100%' });
      TweenMax.set('.video-overlay-video', { display: 'none' });
      activeVideo = document.getElementById('video-overlay-' + video);
      TweenMax.set(activeVideo, { display: 'block' });
      activeVideo.currentTime = 0;
      activeVideo.play();
    };

    App.prototype.closeVideo = function() {
      if (activeVideo && !activeVideo.paused) activeVideo.pause();
      TweenMax.to('#video-overlay-wrapper', 1, { height: 0 });
      decrementScrollRetainCount();
    };

    (function() {
      var activeGallery;
      var activeGalleryItems;
      var activeGalleryLength;
      var activeImageIndex;
      var galleryLeft = $('.gallery-left');
      var galleryRight = $('.gallery-right');

      function update() {
        if (activeImageIndex === 0) {
          galleryLeft.attr('disabled', 'disabled');
        } else {
          galleryLeft.removeAttr('disabled');
        }
        if (activeImageIndex === activeGalleryLength - 1) {
          galleryRight.attr('disabled', 'disabled');
        } else {
          galleryRight.removeAttr('disabled');
        }
        activeGalleryItems.removeClass('active previous next past future');
        if (activeImageIndex > 0) {
          activeGalleryItems.eq(activeImageIndex - 1).addClass('previous');
        }
        activeGalleryItems.eq(activeImageIndex).addClass('active');
        activeGalleryItems.eq(activeImageIndex + 1).addClass('next');
        if (activeImageIndex >= 2) {
          activeGalleryItems
            .filter(':nth-child(' + (activeImageIndex - 1) + ')')
            .addClass('past');
        }
        if (activeImageIndex <= activeGalleryLength - 3) {
          activeGalleryItems
            .filter(':nth-child(' + (activeImageIndex + 3) +')')
            .addClass('future');
        }
      }

      App.prototype.openGallery = function(gallery) {
        loadGalleryImages(gallery);

        incrementScrollRetainCount();
        TweenMax.set('.gallery', { display: 'none' });
        activeGallery = $('#gallery-' + gallery);
        activeGalleryItems = activeGallery.find('li');
        activeGalleryLength = activeGalleryItems.length;
        activeImageIndex = 0;
        update();

        TweenMax.set(activeGallery, { display: 'block' });
        if (gallery === 'people') {
          TweenMax.set('.gallery-close', { color: '#b6b6b6' });
          TweenMax.set('.gallery-close svg line', { stroke: '#b6b6b6' });
          $('.gallery-arrow').removeClass('gallery-arrow-values');
        } else {
          TweenMax.set('.gallery-close', { color: 'black' });
          TweenMax.set('.gallery-close svg line', { stroke: 'black' });
          $('.gallery-arrow').addClass('gallery-arrow-values');
        }
        TweenMax.fromTo('#gallery-wrapper', 1, { height: 0 }, { height: '100%' });
      };

      App.prototype.closeGallery = function() {
        TweenMax.to('#gallery-wrapper', 1, { height: 0 });
        decrementScrollRetainCount();
      };

      App.prototype.galleryPrevious = function() {
        if (activeImageIndex <= 0) {
          return;
        }
        activeImageIndex--;
        update();
      };

      App.prototype.galleryNext = function() {
        if (activeImageIndex >= activeGalleryLength - 1) {
          return;
        }
        activeImageIndex++;
        update();
      };

      function loadGalleryImages(gallery) {
        var list = $('#gallery-' + gallery).children();
        if (list.children().length > 0) {
          return;
        }
        var galleryData = _.find(Aneox.Data.Data.gallery, { id: gallery });
        var images = galleryData && galleryData.image;
        if (!_.isArray(images)) {
          images = [images];
        }
        images.forEach(function(image) {
          list.append('<li><img class="overflow-center" src="' + image + '"></li>');
        });
      }
    }) ();

    // wine pages
    App.prototype.openWinePage = function() {
      incrementScrollRetainCount();
      if (firstLoad) {
        TweenMax.set('#wine-sheet-wrapper', { height: '100%' });
      } else {
        scrollPosition = self.animations.sequences.main.getTime();
        TweenMax.fromTo('#wine-sheet-wrapper', 1, { height: 0 }, { height: '100%' });
      }
    };

    App.prototype.closeWinePage = function() {
      lastScrollAction = 'pageChange';
      setPage.call(self, 'wines');
      if (firstLoad) {
        self.animations.sequences.main.setTime(self.animations.sequences.main.keyframes.winesList);
        self.animations.sequences.wines.fastForwardAnimations();
        firstLoad = false;
      } else {
        self.animations.sequences.main.setTime(scrollPosition);
      }
      decrementScrollRetainCount();
      TweenMax.to('#wine-sheet-wrapper', 1, { height: 0 });
    };

    App.prototype.openVintagePage = function() {
      incrementScrollRetainCount();
      if (firstLoad) {
        TweenMax.set('#vintage-sheet-wrapper', { height: '100%' });
      } else {
        scrollPosition = self.animations.sequences.main.getTime();
        TweenMax.fromTo('#vintage-sheet-wrapper', 1, { height: 0 }, { height: '100%' });
      }
    };

    App.prototype.closeVintagePage = function() {
      lastScrollAction = 'pageChange';
      setPage.call(self, 'wines');
      if (firstLoad) {
        self.animations.sequences.main.setTime(self.animations.sequences.main.keyframes.winesVintages);
        self.animations.sequences.wines.fastForwardAnimations();
        firstLoad = false;
      } else {
        self.animations.sequences.main.setTime(scrollPosition);
      }
      decrementScrollRetainCount();
      TweenMax.to('#vintage-sheet-wrapper', 1, { height: 0 });
    }

    App.prototype.openWineMap = function() {
      incrementScrollRetainCount();
      self.wines.openMap();
      TweenMax.fromTo('#wines-map-overlay-wrapper', 1, { height: 0 }, { height: '100%' });
    };

    App.prototype.closeWineMap = function() {
      TweenMax.to('#wines-map-overlay-wrapper', 1, { height: 0 });
      decrementScrollRetainCount();
    }
    
    var resetAnimations = function () {
      if (self.animations.orderedSequences === undefined) return;
      
      var i;
      var sequence;
      
      var activeSequenceIndex = _.indexOf(self.animations.orderedSequences, self.animations.sequences[currentKeyframeId]);

      for (i = 0; i < activeSequenceIndex; i += 1) {
        sequence = self.animations.orderedSequences[i];
        if (sequence.fastForwardAnimations) sequence.fastForwardAnimations();
      }

      // i += 1;
      
      // for (i; i < self.animations.orderedSequences.length; i += 1) {
      //   sequence = self.animations.orderedSequences[i];
      //   if (sequence.resetAnimations) sequence.resetAnimations();
      // }
    };

    return App;
  })();

  Aneox.App = App;
})(Aneox || (Aneox = {}));