function DissolveFilter(texture) {
    PIXI.AbstractFilter.call(this,
    // vertex shader
        null,
        // fragment shader
        [
            'precision lowp float;',

            'varying vec2 vTextureCoord;',
            
            'uniform sampler2D uSampler;',
            'uniform sampler2D heightMap;',
            
            'uniform float time;',
            'uniform vec3 clampValues;',
            'uniform vec3 startTimes;',
            'uniform vec3 coverColor;',

            'void main(void) {',
            '   vec3 color = texture2D(uSampler, vTextureCoord).rgb;',
            '   vec3 height = texture2D(heightMap, vTextureCoord).rgb;',
            '   vec3 clampedTime;',
            
            '   gl_FragColor.a = 1.0;',

            '   clampedTime.r = clamp(time - startTimes.r, 0.0, clampValues.r);',
            '   if (time > startTimes.r && height.r < clampedTime.r) {',
            '     gl_FragColor.rgb = color;',
            '     return;',
            '   }',
            
            '   clampedTime.g = clamp(time - startTimes.g, 0.0, clampValues.g);',
            '   if (time > startTimes.g && height.g < clampedTime.g) {',
            '     gl_FragColor.rgb = color;',
            '     return;',
            '   }',
            
            '   clampedTime.b = clamp(time - startTimes.b, 0.0, clampValues.b);',
            '   if (time > startTimes.b && height.b < clampedTime.b) {',
            '     gl_FragColor.rgb = color;',
            '     return;',
            '   }',
            
            '   float fade = 1.0;',
            
            '   if (time > startTimes.r) {',
            '    if (height.r < (clampedTime.r + 0.1)) {',
            '      fade = min(fade, (height.r - clampedTime.r) * 10.0);',
            '    }',
            '   }',
            
            '   if (time > startTimes.g) {',
            '    if (height.g < (clampedTime.g + 0.1)) {',
            '      fade = min(fade, (height.g - clampedTime.g) * 10.0);',
            '    }',
            '   }',
            
            '   if (time > startTimes.b) {',
            '    if (height.b < (clampedTime.b + 0.1)) {',
            '      fade = min(fade, (height.b - clampedTime.b) * 10.0);',
            '    }',
            '   }',
            
            '    if (fade != 1.0) {',
            '      gl_FragColor.rgb = color.rgb * (1.0 - fade) + coverColor.rgb * fade;',
            '    } else {',
            '       gl_FragColor.rgb = coverColor;',
            '    }',

            '}'
        ].join('\n'),
        {
            heightMap: { type: 'sampler2D', value: texture },
            time: { type: '1f', value: 0 },
            clampValues: { type: '3f', value: [1,1,1] },
            startTimes: { type: '3f', value: [0,0,0] },
            coverColor: { type: '3f', value: [0,0,0] }
        }
        );

};

DissolveFilter.prototype = Object.create(PIXI.AbstractFilter.prototype);
DissolveFilter.prototype.constructor = DissolveFilter;

Object.defineProperties(DissolveFilter.prototype, {
    time: {
        get: function () {
            return this.uniforms.time.value;
        },
        set: function (value) {
            this.uniforms.time.value = value;
        }
    },
    clampValues: {
        get: function () {
            return this.uniforms.clampValues.value;
        },
        set: function (value) {
            this.uniforms.clampValues.value = value;
        }
    },
    startTimes: {
        get: function () {
            return this.uniforms.startTimes.value;
        },
        set: function (value) {
            this.uniforms.startTimes.value = value;
        }
    },
    coverColor: {
        get: function () {
            return this.uniforms.coverColor.value;
        },
        set: function (value) {
            this.uniforms.coverColor.value = value;
        }
    }
});
