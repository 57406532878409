var Aneox;
(function (Aneox) {
  'use strict';

  var Wines = (function () {

    var self;

    var wine;
    var wineLocale;

    var mapLoaded = false;

    var mapPointers = {
      'batard_x5F_montrachet_1_': 'batard-montrachet',
      'montrachet': 'montrachet',
      'chevalier_x5F_montrachet': 'chevalier-montrachet',
      'meursault_x5F_1er_x5F_cru_x5F_charmes': 'meursault-les-charmes',
      'meursault_x5F_1er_x5F_cru_x5F_perrieres': 'meursault-les-perrieres',
      'meursault_x5F_1er_x5F_cru_x5F_genevrieres': 'meursault-les-genevrieres',
      'meursault_x5F_1er_x5F_cru_x5F_porusot': 'meursault-les-poruzots',
      'bienvenue_x5F_batard_x5F_montrachet_1_': 'bienvenues-batard-montrachet',
      'puligny_x5F_montrachet_x5F_1er_x5F_cru_x5F_mouchere': 'puligny-montrachet-clos-de-la-mouchere',
      'puligny_x5F_montrachet_x5F_1er_x5F_cru_x5F_caillerets': 'puligny-montrachet-les-caillerets',
      'puligny_x5F_montrachet_x5F_1er_x5F_cru_x5F_combettes': 'puligny-montrachet-les-combettes',
      'puligny_x5F_montrachet_x5F_1er_x5F_cru_x5F_folatieres': 'puligny-montrachet-les-folatieres',
      'puligny_x5F_montrachet_x5F_1er_x5F_cru_x5F_perrieres': 'puligny-montrachet-les-perrieres',
      'puligny_x5F_montrachet_x5F_1er_x5F_cru_x5F_pucelles': 'puligny-montrachet-les-pucelles',
      'criots_x5F_batard_x5F_montrachet': 'criots-batard-montrachet',
      'corton_x5F_charlemagne': 'corton-charlemagne',
      'volnay_x5F_1er_x5F_cru_x5F_fremiets_1_': 'volnay-les-fremiets',
      'pommard_x5F_1er_x5F_cru_x5F_rugiens': 'pommard-les-rugiens',
      'volnay_x5F_1er_x5F_cru_x5F_chevrets': 'volnay-les-caillerets',
      'volnay_x5F_1er_x5F_cru_x5F_caillerets_1_': 'volnay-les-chevrets',
      'clos_x5F_vougeot': 'clos-vougeot',
      'bonnes_x5F_mares': 'bonnes-mares',
      'chambertin_x5F_clos_x5F_beze': 'chambertin-close-de-beze',
      'chambertin': 'chambertin',
      'pommard_x5F_1er_x5F_cru_x5F_closblanc': 'pommard-clos-blanc',
      'meursault_x5F_les_x5F_gouttes_x5F_dor': 'meursault-les-gouttes-dor',
      'meursault_x5F_clos_x5F_richemont': 'meursault-clos-richemont'
    }

    function Wines(locale, router) {
      self = this;
      self.router = router;

      this.router.bind('pageChange', function(path, node, nodeLocale) {
        self.locale = nodeLocale;
        self.texts = _.find(Aneox.Data.Data.wineText, { locale: self.locale.locale });
      });

      var open = false;
      var selectHeight = $('#wine-millesime-select').innerHeight()
      $('.wine-millesime-select-wrapper')
        .on('click', '.wine-millesime-selected', function() {
          if (open) {
            open = false;
            TweenMax.to('.wine-millesime-select-wrapper', .3, { height: 28 });
            TweenMax.to('.wine-millesime-selected-arrow', .3, { rotation: 360 });
          } else {
            open = true;
            TweenMax.to('.wine-millesime-select-wrapper', .3, {
              height: selectHeight + 28
            });
            TweenMax.fromTo('.wine-millesime-selected-arrow', .3,
              { rotation: 0 }, { rotation: 180 });
          }
        })
        .on('click', '.wine-millesime-select-option:not(.wine-millesime-selected)', function() {
          open = false;
          $('.wine-millesime-selected').text($(this).text());
          var millesime = _.find(wineLocale.millesime, { year: '' + $(this).data('millesime') });
          updateMillesime(millesime);
          TweenMax.to('.wine-millesime-select-wrapper', .3, { height: 28 });
          TweenMax.to('.wine-millesime-selected-arrow', .3, { rotation: 360 });
        });
    }

    Wines.prototype.setupList = function(list, type, locale) {
      var listElement = $('#wines-list-' + list + '-' + type);
      listElement.empty();
      var wines = Aneox.Data.Data.wine.sort(function(wine1, wine2) {
        var order1 = parseInt(wine1.order);
        var order2 = parseInt(wine2.order);
        if (order1 < order2) {
          return -1;
        } else if (order1 > order2) {
          return 1;
        } else {
          return 0;
        }
      });
      _.each(_.filter(wines, {
        type: type,
        list: list
      }), function(wine) {
        listElement.append('<li>' +
          '<a href="' + self.router.getNodePath(wine.id, locale) + '"><span>'
            + wine.name + ' ' + (wine.name2 || '') + '</span></a>' +
        '</li>');
      });
    };

    Wines.prototype.loadMap = function() {
      if (mapLoaded) return;

      //zoom reset 
      if (self.panZoom) self.panZoom.panzoom('destroy');
      self.panZoom = undefined;
      $('#wines-map-svg').empty();

      // initialise map
      self.snap = Snap('#wines-map-svg');
      Snap.load('assets/vins/cote_beaune.svg', function(svg) {
        self.snap.append(svg);

        // zoom setup
        self.panZoom = $('#wines-map-svg svg').panzoom({
          minScale: 1,
          maxScale: 10,
          contain: 'invert'
        }).panzoom('zoom', 1.5);
      });
      mapLoaded = true;
    };

    Wines.prototype.openMap = function() {
      if (!mapLoaded) {
        alert('not loaded');
        setTimeout(function() {
          self.openMap();
        }, 100);
        return;
      }

      // zoom mousewheel listener
      var firefox  = navigator.userAgent.indexOf('Firefox') > -1;
      if(!firefox) {
        $('#wines-map-svg').on('mousewheel.focal', _.throttle(function(e) {
          e.preventDefault();
          var delta = e.delta || e.originalEvent.wheelDelta;
          var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
          self.panZoom.panzoom('zoom', zoomOut, {
            increment: 1,
            animate: true,
            focal: e
          });
        }, 500, { leading: true, trailing: false }));
      }

      // introduce finer content at higher zoom level
      self.panZoom.on('panzoomzoom', updateMapDetail);

      // load Nuits map
      Snap.load('assets/vins/cote_nuits.svg', function(svg) {
        self.snap.append(svg);

        // pointer behaviour on hover and click
        $('#wines-map-svg svg').each(function() {
          var map = this;
          $(map).find('#pointeurs, #nuits-pointeurs').children().each(function() {
            var pointer = Snap('#' + this.id);
            var pointerArea = Snap();
            var pointerChildren = pointer.children();
            var pointerCircle = pointerChildren.filter(function(child) {
              return (child.type === 'circle');
            })[0];
            var pointerName = pointerChildren.filter(function(child) {
              return (child.type === 'g')
            })[0];
            var cx = pointerCircle.attr('cx');
            var cy = pointerCircle.attr('cy');
            var circle = pointerArea.circle(cx, cy, 10);
            var id = mapPointers[this.id];
            circle.attr({
              class: 'wines-map-clickzone',
              id: 'wines-map-clickzone-' + id
            });
            Snap('#' + map.id).append(circle);
            circle.hover(function() {
              pointerCircle.transform(
                Snap.matrix(1,0,0,1,0,0)
                    .scale(1.3, 1.3, cx, cy)
                    .toTransformString()
              );
              if (self.panZoom.panzoom('getMatrix')[0] < 4) {
                // if map is too zoomed out for names to show, show wine name on hover
                if (map.id === 'wines-map-svg-beaune') {
                  Snap('#pointeurs').append(pointer);
                } else {
                  Snap('#nuits-pointeurs').append(pointer);
                }
                TweenMax.to('#' + map.id + ' #' + pointerName.attr('id'), .5, { display: 'block', opacity: 1 });
              }
            }, function() {
              pointerCircle.transform(Snap.matrix(1,0,0,1,0,0).toTransformString());
              TweenMax.to('#' + map.id + ' #' + pointerName.attr('id'), .5, { opacity: 0, display: 'none' });
            });

            circle.click(function() {
              self.router.setPath(self.router.getNodePath(id, self.locale.locale));
            });
          })
        });
      });
    };

    Wines.prototype.zoomMap = function(zoomOut) {
      self.panZoom.panzoom('zoom', zoomOut, {
        increment: 1,
        animate: true,
        focal: {
          clientX: $('#wines-map-svg').innerWidth() * 0.5,
          clientY: $('#wines-map-svg').innerHeight() * 0.5
        }
      });
    };

    Wines.prototype.switchMap = function(map) {
      var otherMap = (map === 'beaune' ? 'nuits' : 'beaune');
      TweenMax.set('#noms, #nom', { opacity: 0, display: 'none' })

      TweenMax.to('#wines-map-svg-beaune', 1, {
        transform: 
          map === 'nuits' ? 'matrix(1,0,0,1,-' + document.body.clientWidth + ',0)'
          : 'matrix(1.5,0,0,1.5,0,0)'
      });
      TweenMax.to('#wines-map-svg-nuits', 1, {
        transform:
          map === 'beaune' ? 'matrix(1,0,0,1,' + document.body.clientWidth + ',0)'
            : 'matrix(1.5,0,0,1.5,0,0)'
      });

      TweenMax.to('#button-switchmap-' + map, 1, { opacity: 0, display: 'none' });
      TweenMax.to('#button-switchmap-' + otherMap, 1, { opacity: 1, display: 'block' });

      self.panZoom.panzoom('destroy');
      self.panZoom = $('#wines-map-svg-' + map).panzoom({
        minScale: 1,
        maxScale: 10,
        contain: 'invert'
      });

      // introduce finer content at higher zoom level
      self.panZoom.on('panzoomzoom', updateMapDetail);
    };

    Wines.prototype.openPage = function(nodeId) {
      wine = _.find(Aneox.Data.Data.wine, { id: nodeId });
      wineLocale = _.find(wine.locale, { locale: self.locale.locale });
      if (!_.isArray(wineLocale.millesime)) {
        wineLocale.millesime = [wineLocale.millesime];
      }
      replaceContent(wine, wineLocale);
      app.openWinePage();
    };

    function replaceContent(wine, wineLocale) {
      $('#wine-sheet').css('background-image',
        wine.photo ? 'url("' + wine.photo + '")' : 'none');
      $('.wine-sheet-value:not(.wine-sheet-millesime-value)')
        .text('').hide()
        .prev().hide();
      $('#wine-name2').text('');
      wine = _.merge({}, wine, wineLocale);
      _.each(wine, function(value, key) {
        var valueText;
        if (['locale', 'millesime', 'photo'].indexOf(key) > -1) return;
        if (_.isArray(value)) {
          valueText = value.slice(0, -1).join(', ') +
            ' ' + self.texts.et + ' ' + _.last(value);
        } else {
          valueText = value;
        }
        $('#wine-' + key)
          .text(valueText).show()
          .prev().show();
      });

      updateMillesime(wineLocale.millesime[0]);

      // set up millésime dropdown list
      $('.wine-millesime-selected').text(self.texts.millesime + ' ' + wineLocale.millesime[0].year);
      var dropdown = $('#wine-millesime-select').empty();
      _.each(wineLocale.millesime, function(millesime) {
        millesime = millesime.year;
        dropdown.append('<li class="wine-millesime-select-option"' +
          'data-millesime="' + millesime + '">' + self.texts.millesime + ' ' +
          millesime + '</li>');
      });
    }

    function updateMapDetail(e, panzoom, scale) {
      if(scale >= 4){
        TweenMax.to("#noms, #nom:not(#pointeurs #nom)", .5, {opacity:1, display: "block"}); 
      } else {
        TweenMax.to("#noms, #nom:not(#pointeurs #nom)", .5, {opacity:0, display: "none"});
      }
    }

    function updateMillesime(millesime) {
      
      $('.wine-sheet-millesime-value')
        .text('')
        .hide()
        .prev().hide();
      _.each(millesime, function(value, key) {

        if (key === 'tasting') {
          _.each(value, function(value, key) {
            $('#wine-' + key)
              .text(value).show()
              .prev().show();
          });
        } else if(key === 'village'){

          var villageString = '';
          var villageIndex = 0;
          var numberOfVillages = millesime.village.length;

          if(_.isArray(value)){
            _.each(value, function(village, key){
              villageString += (villageIndex == 0 )? village : ', '+village;
              villageIndex++;
            });
          }else{
            villageString = value;
          }

          $('#wine-' + key)
            .text(villageString).show()
            .prev().show();
        }
         else {

          if(value.length > 0){
            $('#wine-' + key)
            .text(value).show()
            .prev().show();
          }

          
        }
      });

      // set up download link
      $('#wine-sheet-download').attr('href',
        'pdf.php?wine_id=' + wine.id +
        '&locale=' + self.locale.locale +
        '&vintage=' + millesime.year);
    }

    Wines.prototype.openVintagePage = function(nodeId) {
      var year = nodeId.slice(-4);
      var vintage = _.find(Aneox.Data.Data.vintage, { year: year });
      var vintageLocale = _.find(vintage.locale, { locale: self.locale.locale });
      $('#vintage-sheet-year').text(vintage.year);
      var vintageSheetText1 = $('#vintage-sheet-text-1');
      var vintageSheetText2 = $('#vintage-sheet-text-2');
      vintageSheetText1.html(vintageLocale.text1);
      vintageSheetText2.html(vintageLocale.text2);

      vintageSheetText2.append(
        '<a target="_blank" href="' + vintageLocale.pdf +
        '" class="button button-solid button-pdf center-block">' +
          '<span>' + self.texts.download + '</span>' +
        '</a>');

      app.openVintagePage();
    };
    
    MicroEvent.mixin(Wines);

    return Wines;
  })();

  Aneox.Wines = Wines;
})(Aneox || (Aneox = {}));