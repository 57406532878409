var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Wines = (function () {

      var self;

      var wines;
      var winesVisibility;
      
      var hasPlayedThroughAnimation;

      var timeline;

      var video1;
      var heroVideo;

      function Wines(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        wines = $('#wines');

        // insert videos
        // video1 = resources['wines-video-1'].data;
        // video1.id = 'wines-video-1';
        // video1.className = 'wines-video-1';
        // video1.muted = true;
        // video1.loop = true;
        // $('.wines-glasses').after(video1);
        video1 = document.getElementById('wines-video-1');
        // heroVideo = resources['wines-hero'].data;
        // heroVideo.id = 'wines-hero';
        // heroVideo.className = 'hero-video overflow-center';
        // heroVideo.muted = true;
        // heroVideo.loop = true;
        // $('.wines-hero').append(heroVideo);
        heroVideo = document.getElementById('wines-hero');

        // insert grape images
        /* $('.wines-mast-hero-bg1').css('background-image', 'url(assets/vins/fond_raisins_rouge-90.jpg)');
        var fg1 = self.resources['wines-mast-hero-fg1'].data;
        fg1.className = 'wines-mast-hero-fg1';
        var fg2 = self.resources['wines-mast-hero-fg2'].data;
        fg2.className = 'wines-mast-hero-fg2';
        $('.wines-mast-hero').append(fg1).append(fg2); */

        // set up timeline
        timeline = new TimelineMax({ paused: true });
        timeline
          // mast enter animations
          .from('.wines-mast-title', 1, { opacity: 0 })
          .from('.wines-mast-title-rule', 1, { transform: 'scaleX(0)' }, '-=.5')
          .from('.wines-mast-hero p', 1, { opacity: 0 }, '-=.5')
          .addPause()
          // main enter animations
          .add('main')
          .from('.wines-main-pre-title, .wines-main-title', 1, { opacity: 0 })
          .from('.wines-main-title-rule', 1, { transform: 'scaleX(0)' }, '-=.5')
          .from('.wines-main-lead', 1, { opacity: 0 }, '-=.5')
          .addPause()
          // main scroll animations
          .add('mainScroll')
          .from('.wines-main-closing p', 1, { opacity: 0 })
          .from('.wines-main-closing hr', 1, { transform: 'scaleX(0)' }, '-=.5')
          .addPause()
          .add('map')
          .call(app.wines.loadMap, null, self.wines)
          .from('.wines-map-mask', 1, { opacity: 1 })
          .addPause()
          // end animation
          .add('end')
          .to('#wines-end-background', 5, { transform: 'scale(1.15)' });
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = wines.css('display');
              var previousWinesVisibility = winesVisibility;
              winesVisibility = display !== 'none';
              
              if (!previousWinesVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(wines[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Wines.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        //if (!domainVisibility) return;
      };
      
      Wines.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        timeline.progress(0);
      };
      
      Wines.prototype.fastForwardAnimations = function () {
        // this.resetAnimations();
        // this.mastEnterAnimation(true, 0);
        app.wines.loadMap();
        timeline.pause().progress(1);
        TweenMax.set([
          '.wines-mast-title',
          '.wines-mast-title-rule',
          '.wines-mast-hero p',
          '.wines-main-pre-title',
          '.wines-main-title',
          '.wines-main-title-rule',
          '.wines-main-lead',
          '.wines-main-closing p',
          '.wines-main-closing hr'
        ], { willChange: 'auto' });
      };
      
      Wines.prototype.mastEnterAnimation = function (force, timescale) {
        if (!heroVideo.paused) heroVideo.pause();
        if (video1.paused && !this.animations.isTouchDevice) {
          video1.play();
        }
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        timeline.play();
        hasPlayedThroughAnimation.mast = true;
      };

      Wines.prototype.mainEnterAnimation = function() {
        if (video1.paused && !this.animations.isTouchDevice) {
          video1.play();
        }
        if (heroVideo.paused && !this.animations.isTouchDevice) {
          heroVideo.play();
        }
        if (hasPlayedThroughAnimation.main) return;
        timeline.seek('main').play();
        hasPlayedThroughAnimation.main = true;
      };

      Wines.prototype.mainScrollAnimation = function() {
        if (!video1.paused) video1.pause();
        if (hasPlayedThroughAnimation.mainScroll) return;
        timeline.seek('mainScroll').play();
        hasPlayedThroughAnimation.mainScroll = true;
      };

      Wines.prototype.mapEnterAnimation = function() {
        if (hasPlayedThroughAnimation.map) return;
        timeline.seek('map').play();
        hasPlayedThroughAnimation.map = true;
      };

      Wines.prototype.endAnimation = function() {
        if (heroVideo.paused && !this.animations.isTouchDevice) {
          heroVideo.play();
        }
        if (hasPlayedThroughAnimation.end) return;
        timeline.seek('end').play();
        hasPlayedThroughAnimation.end = true;
        TweenMax.set([
          '.wines-mast-title',
          '.wines-mast-title-rule',
          '.wines-mast-hero p',
          '.wines-main-pre-title',
          '.wines-main-title',
          '.wines-main-title-rule',
          '.wines-main-lead',
          '.wines-main-closing p',
          '.wines-main-closing hr'
        ], { willChange: 'auto' });
      };

      return Wines;
    })();

    Sequences.Wines = Wines;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));