var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Contact = (function () {

      var self;

      var contact;
      var contactVisibility;

      var diaporamaVideo;
      
      var hasPlayedThroughAnimation;

      var timeline;

      function Contact(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        contact = $('#contact');

        timeline = new TimelineMax({ paused: true });

        // Set up map SVG
        Snap.load('assets/contact/pointeur_domaine_carte.svg', function(svg) {
          svg.width = 109;
          svg.height = 121;
          Snap('#contact-svg').append(svg);

          // Set up timeline
          timeline
            // mast enter animations
            .from('.contact-title', .5, { opacity: 0 })
            .from('.contact-title-rule', .5, { transform: 'scaleX(0)' })
            .from('.contact-lead', .5, { opacity: 0 })
            .from('.contact-text', 1, { color: 'rgba(0,0,0,0)', width: 0 })
            .from('#contact-cercle', 1, { opacity: 0, scale: 1.5, transformOrigin: 'center' })
            .from('#contact-point', 1, { scale: 0, transformOrigin: 'center' }, '-=1')
            .from('#contact-logo', .5, { opacity: 0 });

        });
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = contact.css('display');
              var previousContactVisibility = contactVisibility;
              contactVisibility = display !== 'none';
              
              if (!previousContactVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(contact[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Contact.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
      };
      
      Contact.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        timeline.progress(0);
      };
      
      Contact.prototype.fastForwardAnimations = function () {
        timeline.pause().progress(1);
      };
      
      Contact.prototype.mastEnterAnimation = function (force, timescale) {
        var winesHero = document.getElementById('wines-hero');
        if (!winesHero.paused) winesHero.pause();
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        timeline.play();
        hasPlayedThroughAnimation.mast = true;
      };

      return Contact;
    })();

    Sequences.Contact = Contact;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));