var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var People = (function () {

      var self;

      var people;
      var peopleVisibility;

      var diaporamaVideo;
      
      var hasPlayedThroughAnimation;

      var timeline;

      function People(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        people = $('#people');

        // insert video
        // diaporamaVideo = resources['people-diaporama-video'].data;
        // diaporamaVideo.id = 'people-diaporama-video';
        // diaporamaVideo.className = 'people-diaporama-video overflow-center';
        // diaporamaVideo.muted = true;
        // diaporamaVideo.loop = true;
        // $('.people-diaporama-video-container').append(diaporamaVideo);
        diaporamaVideo = document.getElementById('people-diaporama-video');

        // Set up timeline
        timeline = new TimelineMax({ paused: true });
        timeline
          // mast enter animations
          .from('.people-mast-title', 1, { opacity: 0 }, .1)
          .from('.people-mast-title-rule', 1, { scaleX: 0 }, '-=.5')
          .addPause()
          // vocation enter animations
          .add('vocation')
          .from('.people-vocation-bg', 7, { opacity: 0 })
          .staggerFrom('.people-vocation-pre-title, .people-vocation-title',
            1, { opacity: 0 }, .2, '-=6.5')
          .from('.people-vocation-rule', 1, { scaleX: 0 }, '-=6.2')
          .from('.people-vocation-lead', 1, { opacity: 0 }, '-=5.9')
          .addPause()
          // end animation
          .add('end')
          .to('#people-end-background', 5, { scale: 1.15 });
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = people.css('display');
              var previousPeopleVisibility = peopleVisibility;
              peopleVisibility = display !== 'none';
              
              if (!previousPeopleVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(people[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      People.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        //if (!domainVisibility) return;
      };
      
      People.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        timeline.progress(0);
      };
      
      People.prototype.fastForwardAnimations = function () {
        // this.resetAnimations();
        // this.mastEnterAnimation(true, 0);
        timeline.pause().progress(1);
        TweenMax.set([
          '.people-mast-title',
          '.people-mast-title-rule',
          '.people-vocation-bg',
          '.people-vocation-pre-title',
          '.people-vocation-title',
          '.people-vocation-rule',
          '.people-vocation-lead'
        ], { willChange: 'auto' });
      };
      
      People.prototype.mastEnterAnimation = function (force, timescale) {
        if (!diaporamaVideo.paused) diaporamaVideo.pause();
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        timeline.play();
        hasPlayedThroughAnimation.mast = true;
      };

      People.prototype.vocationEnterAnimation = function() {
        if (diaporamaVideo.paused && !this.animations.isTouchDevice) {
          diaporamaVideo.play();
        }
        if (hasPlayedThroughAnimation.vocation) return;
        timeline.seek('vocation').play();
        hasPlayedThroughAnimation.vocation = true;
      };

      People.prototype.endAnimation = function() {
        if (diaporamaVideo.paused && !this.animations.isTouchDevice) {
          diaporamaVideo.play();
        }
        if (hasPlayedThroughAnimation.end) return;
        timeline.seek('end').play();
        hasPlayedThroughAnimation.end = true;
        TweenMax.set([
          '.people-mast-title',
          '.people-mast-title-rule',
          '.people-vocation-bg',
          '.people-vocation-pre-title',
          '.people-vocation-title',
          '.people-vocation-rule',
          '.people-vocation-lead'
        ], { willChange: 'auto' });
      };

      return People;
    })();

    Sequences.People = People;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));