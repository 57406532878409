var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var History = (function () {

      var self;

      var history;
      var historyVisibility;

      var timeline2017Video;
      var heroVideo;
      
      var hasPlayedThroughAnimation;

      var timeline;

      function History(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        history = $('#history');

        // insert videos
        // timeline2017Video = resources['history-2017-video'].data;
        // timeline2017Video.id = 'history-2017-video';
        // timeline2017Video.muted = true;
        // timeline2017Video.loop = true;
        // $('.timeline-2017').append(timeline2017Video);
        timeline2017Video = document.getElementById('history-2017-video');
        // heroVideo = resources['history-hero'].data;
        // heroVideo.id = 'history-hero';
        // heroVideo.className = 'hero-video overflow-center';
        // heroVideo.muted = true;
        // heroVideo.loop = true;
        // $('#history-hero-wrapper').append(heroVideo);
        heroVideo = document.getElementById('history-hero');

        // set up timeline
        timeline = new TimelineMax({ paused: true });
        timeline
          // mast enter animations
          .from('.history-title', .5, { opacity: 0 })
          .from('.history-title-rule', .5, { scaleX: 0 }, '-=.2')
          .from('.timeline-circle', .75, { scale: 0 }, '-=.2')
          .from('.history-background-1', 1, { opacity: 0 }, '-=.7')
          .from('.timeline-1885-line', 4, { scaleY: 0 }, '-=.7')
          .staggerFrom([
              '.history-1885-1',
              '.history-visual-1-wrapper',
              '.history-visual-2-wrapper',
              '.history-1885-2',
              '.history-visual-3-wrapper',
              '.history-1885-3',
              '.history-visual-4-wrapper'
            ], 1, { opacity: 0 }, .2, '-=3.7')
          .addPause()
          // timeline-2017 enter animations
          .add('timeline2017')
          .from('.history-year-2017', 1, { opacity: 0 })
          .from('.history-background-2', 1, { opacity: 0 }, '-=1')
          .from('.timeline-2017-line', 2, { scaleY: 0 }, '-=.7')
          .staggerFrom([
            '.history-visual-6-wrapper',
            '.history-2017-1'
          ], 1, { opacity: 0 }, .2, '-=1.7')
          .addPause()
          // main enter animations
          .add('main')
          .staggerFrom([
            '.history-main-pre-title',
            '.history-main-title'
          ], 1, { opacity: 0 }, .3)
          .from('.history-main-title-rule', 1, { scaleX: 0 })
          .staggerFrom([
            '.history-main-lead',
            '.history-hero'
          ], 1, { opacity: 0 }, .3, '-=.7')
          .addPause()
          // end animation
          .add('end')
          .to('#history-end-background', 5, { scale: 1.15 });
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = history.css('display');
              var previousHistoryVisibility = historyVisibility;
              historyVisibility = display !== 'none';
              
              if (!previousHistoryVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(history[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      History.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        //if (!domainVisibility) return;
      };
      
      History.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        timeline.progress(0);
      };
      
      History.prototype.fastForwardAnimations = function () {
        // this.resetAnimations();
        // this.mastEnterAnimation(true, 0);
        timeline.pause().progress(1);
        TweenMax.set([
          '.history-title',
          '.history-title-rule',
          '.history-background-1',
          '.timeline-line',
          '.history-1885-1',
          '.history-visual-1-wrapper',
          '.history-visual-2-wrapper',
          '.history-1885-2',
          '.history-visual-3-wrapper',
          '.history-1885-3',
          '.history-visual-4-wrapper',
          '.history-year-2017',
          '.history-background-2',
          '.history-visual-6-wrapper',
          '.history-2017-1',
          '.history-main-pre-title',
          '.history-main-title',
          '.history-main-title-rule',
          '.history-main-lead',
          '.history-hero'
        ], { willChange: 'auto' });
      };
      
      History.prototype.mastEnterAnimation = function (force, timescale) {
        var video = document.getElementById('people-diaporama-video');
        if (!video.paused) video.pause();
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        timeline.play();   
        hasPlayedThroughAnimation.mast = true;
      };

      History.prototype.timeline2017EnterAnimation = function() {
        if (!heroVideo.paused) heroVideo.pause();
        if (hasPlayedThroughAnimation.timeline2017) return;
        timeline.seek('timeline2017').play();
         if (!this.animations.isTouchDevice) timeline2017Video.play();
        hasPlayedThroughAnimation.timeline2017 = true;
      };

      History.prototype.mainEnterAnimation = function() {
        if (heroVideo.paused && !this.animations.isTouchDevice) {
          heroVideo.play();
        }
        if (hasPlayedThroughAnimation.main) return;
        timeline.seek('main').play();
        hasPlayedThroughAnimation.main = true;
      };

      History.prototype.endAnimation = function() {
        if (heroVideo.paused && !this.animations.isTouchDevice) {
          heroVideo.play();
        }
        if (hasPlayedThroughAnimation.end) return;
        timeline.seek('end').play();
        hasPlayedThroughAnimation.end = true;
        TweenMax.set([
          '.history-title',
          '.history-title-rule',
          '.history-background-1',
          '.timeline-line',
          '.history-1885-1',
          '.history-visual-1-wrapper',
          '.history-visual-2-wrapper',
          '.history-1885-2',
          '.history-visual-3-wrapper',
          '.history-1885-3',
          '.history-visual-4-wrapper',
          '.history-year-2017',
          '.history-background-2',
          '.history-visual-6-wrapper',
          '.history-2017-1',
          '.history-main-pre-title',
          '.history-main-title',
          '.history-main-title-rule',
          '.history-main-lead',
          '.history-hero'
        ], { willChange: 'auto' });
      };

      return History;
    })();

    Sequences.History = History;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));